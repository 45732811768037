import { Stack } from "@mui/material";
import React from "react";
import PersonalizedInput from "../../../components/personalizedInput";
import PersonalizedButton from "../../../components/personalizedButton";
import { getAuth, updateEmail, updateProfile } from "firebase/auth";

export default function EditForms() {
  const [defaultForms, setDefaultForms] = React.useState({
    name: "",
    lastName: "",
    password: "",
    email: "",
    phone: "",
  });

  const auth = getAuth();
  const clickUpdateProfile = () => {
    console.log(defaultForms);
    if (
      auth.currentUser &&
      defaultForms.name !== "" &&
      defaultForms.lastName !== ""
    ) {
      console.log("a");
      updateProfile(auth.currentUser, {
        displayName: `${defaultForms.name + " " + defaultForms.lastName}`,
      })
        .then(() => {
          console.log("Success");
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (auth.currentUser && defaultForms.email !== "") {
      updateEmail(auth.currentUser, defaultForms.email)
        .then(() => {
          console.log("Success");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const setFormField = (field: string, value: string) => {
    setDefaultForms((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  return (
    <Stack spacing={3} padding={"32px"}>
      <Stack direction={"row"} spacing={10}>
        <PersonalizedInput
          inputType="text"
          text="Nome"
          variant="primary"
          setStoredText={(text) => setFormField("name", text)}
          placeholder={auth.currentUser?.displayName?.split(" ")[0]}
        />
        <PersonalizedInput
          inputType="password"
          text="Sobrenome"
          variant="transparent"
          setStoredText={(text) => setFormField("lastName", text)}
          placeholder={auth.currentUser?.displayName?.split(" ")[1]}
        />
      </Stack>

      <PersonalizedInput inputType="text" text="Senha" variant="primary" />
      <PersonalizedInput
        inputType="text"
        text="Email"
        variant="primary"
        setStoredText={(text) => setFormField("email", text)}
        placeholder={auth.currentUser?.email?.split(" ")[0]}
      />
      <PersonalizedInput inputType="text" text="Telefone" variant="primary" />
      <PersonalizedButton
        text="Salvar alterações"
        variant="primary"
        onClick={clickUpdateProfile}
      />
    </Stack>
  );
}
