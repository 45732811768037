import React from "react";
import LoginLayout from "../../layout/loginLayout";
import styles from "./index.module.scss";
import { Box, Grid } from "@mui/material";
import ImageDisplay from "./imageDisplay/ImageDisplay";
import LoginForm from "./form/loginForm";
import SuccessScreen from "./form/SuccessScreen/SuccessScreen";
import RegisterScreen from "./form/RegisterScreen/RegisterScreen";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebase";
import { SuccessType } from "../../domain";

export default function Login() {
  const [successfulLogin, setSuccessfulLogin] = React.useState<SuccessType>({
    message: "",
    isSuccess: false,
  });
  const [isRegistering, setIsRegistering] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const [initialized, setInitialized] = React.useState(false);

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const timer = setTimeout(() => {
          navigate("/home");
        }, 3000);
        return () => {
          clearTimeout(timer);
        };
      }
      setInitialized(true);
    });
    return () => unsubscribe();
  }, [navigate]);

  React.useEffect(() => {
    if (successfulLogin.isSuccess) {
      const registerTimer = setTimeout(() => {
        setIsRegistering(false);
      }, 2000);
      const timer = setTimeout(() => {
        setSuccessfulLogin({ message: "", isSuccess: false });
        navigate("/home");
      }, 3000);
      return () => {
        clearTimeout(timer);
        clearTimeout(registerTimer);
      };
    }
  }, [successfulLogin.isSuccess, navigate]);

  if (!initialized) {
    return <div>Loading...</div>;
  }

  return (
    <LoginLayout>
      <Box className={styles.loginWrapper}>
        {isRegistering && (
          <RegisterScreen
            setSuccessfulLogin={setSuccessfulLogin}
            setIsRegistering={setIsRegistering}
          />
        )}
        {successfulLogin.isSuccess && (
          <SuccessScreen message={successfulLogin.message} />
        )}
        <Grid container height={"100%"}>
          <Grid item xs={5}>
            {!isRegistering && <ImageDisplay />}
          </Grid>
          <Grid item xs={7}>
            <LoginForm
              setSuccessfulLogin={setSuccessfulLogin}
              setIsRegistering={setIsRegistering}
            />
          </Grid>
        </Grid>
      </Box>
    </LoginLayout>
  );
}
