import React, { useEffect, useState, ChangeEvent, CSSProperties } from "react";
import axios from "axios";
import { AutoComplete, Input } from "antd";
import type { SelectProps } from "antd/es/select";
import styles from "./index.module.scss";

interface City {
  nome: string;
}

const inputStyle: CSSProperties = {
  outline: "none",
  boxShadow: "none",
  border: "0px solid black",
  backgroundColor: "#e5e5e5",
  fontFamily: "Poppins",
};

type Props = {
  city: string;
  setCity: (city: string) => void;
};

export default function CityAutoComplete({ city, setCity }: Props) {
  const [cities, setCities] = useState<string[]>([]);
  const [options, setOptions] = useState<SelectProps["options"]>([]);

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await axios.get<City[]>(
          "https://servicodados.ibge.gov.br/api/v1/localidades/municipios"
        );
        const cityNames = response.data.map((city) => city.nome);
        setCities(cityNames);
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
    };

    fetchCities();
  }, []);

  const handleInputChange = (inputValue: string) => {
    setCity(inputValue);
    const filteredOptions = cities
      .filter((city) => city.toLowerCase().includes(inputValue.toLowerCase()))
      .map((city) => ({ value: city }));
    setOptions(filteredOptions);
  };

  return (
    <AutoComplete
      value={city}
      options={options}
      style={{ width: 300 }}
      onChange={handleInputChange}
      placeholder={"Escolha uma cidade"}
    >
      <Input style={inputStyle} placeholder="Escolha uma cidade" />
    </AutoComplete>
  );
}
