import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import styles from './index.module.scss';
import { Box, Stack } from '@mui/material';
import { FaCalendarDay, FaAngleDown } from 'react-icons/fa';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';

// Create a custom theme to override MUI TextField and DatePicker styles
const customTheme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            border: 'none !important',
            boxShadow: 'none',
            padding: 0,
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: 'none !important',
            },
            '&:hover fieldset': {
              border: 'none !important',
            },
            '&.Mui-focused fieldset': {
              border: 'none !important',
            },
          },
          '& .MuiInputBase-input': {
            padding: '4px 0px',
            fontFamily: 'Poppins',
            border: 'none',
          },
          '& .MuiInputAdornment-root': {
            display: 'none',
          },
        },
      },
    },
  },
});

type Props = {
  date: Dayjs | null;
  setDate: (date: Dayjs | null) => void;
};

export default function ResponsiveDatePickers({ date, setDate }: Props) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ThemeProvider theme={customTheme}>
      <Box className={styles.datepickerWrapper} onClick={handleOpen}>
        <Stack
          direction="row"
          spacing={2}
          alignItems={'center'}
          className={styles.contentWrapper}
        >
          <Box className={styles.datePickerButton}>
            <FaCalendarDay />
          </Box>
          <Stack direction="column">
            <Stack direction="row" alignItems={'center'} spacing={0.5}>
              <p>DATA</p>
              <FaAngleDown />
            </Stack>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="en-gb"
            >
              <DatePicker
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                value={date}
                onChange={(value) => {
                  setDate(value);
                  setOpen(false);
                }}
                format="DD-MM-YYYY"
                className={styles.customDatePicker}
              />
            </LocalizationProvider>
          </Stack>
          <Box className={styles.dateSelect}>Selecionar</Box>
        </Stack>
      </Box>
    </ThemeProvider>
  );
}
