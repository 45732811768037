import React from 'react';
import MultistepProgress from '../../components/multistepProgress';
import { MdOutlineCreateNewFolder } from 'react-icons/md';
import { FaRegHandshake } from 'react-icons/fa';
import { TiTickOutline } from 'react-icons/ti';
import { Box, Stack } from '@mui/material';
import styles from './index.module.scss';
import PersonalizedButton from '../../components/personalizedButton';
import Criação from './FormParts/Criação';
import Contrapartidas from './FormParts/Contrapartidas';
import Aceite from './FormParts/Aceite';
import CreateNewPartnership from './CreateNewPartnership';
import SearchPartnership from './SearchPartnership';
import { SearchPartnershipContext } from '../../hooks/SearchPartnershipContext';

export default function Partnership() {
  const [isCreatingNewPartnership, setIsCreatingNewPartnership] =
    React.useState<boolean>(false);

  return (
    <SearchPartnershipContext.Provider
      value={{ isCreatingNewPartnership, setIsCreatingNewPartnership }}
    >
      <Stack
        className={
          !isCreatingNewPartnership
            ? styles.partnershipWrapper
            : styles.partnershipFormWrapper
        }
      >
        {!isCreatingNewPartnership ? (
          <SearchPartnership />
        ) : (
          <CreateNewPartnership />
        )}
      </Stack>
    </SearchPartnershipContext.Provider>
  );
}
