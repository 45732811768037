import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import PersonalizedCard from '../../../../components/personalizedCard';
import { FaCalendarAlt } from 'react-icons/fa';
import { FaLocationDot } from 'react-icons/fa6';
import { MdDevices } from 'react-icons/md';

import {
  InformacoesMacLife,
  ReportInfo,
  ReportsData,
  WeatherData,
} from '../../dtos';
import { fetchWeatherData } from '../../../../hooks/FetchData';
import { weatherConditionTranslations } from '../../../../utils/weatherTranslation';
import { FaCloud } from 'react-icons/fa';
import Chart from './Chart';
import infosEvent from '../../../../resources/macLifeData.json';
import { useReportsContext } from '../../../../hooks/ReportsContext';
import { useParams } from 'react-router-dom';
import { FaTicketAlt } from 'react-icons/fa';
import { FaMoneyBillWave } from 'react-icons/fa';
import { GiAchievement } from 'react-icons/gi';
import { MdProductionQuantityLimits } from 'react-icons/md';
import { FaPerson } from 'react-icons/fa6';
import { FaChartArea } from 'react-icons/fa';
import { MdOutlinePointOfSale } from 'react-icons/md';
import styles from './index.module.scss';

type Props = {
  reportsData: ReportsData;
};

export default function Characteristics({ reportsData }: Props) {
  const API_KEY = 'KU9RFDMSS35BDD28FL4CYETAL';
  const LAT = '48.137154';
  const LON = '11.576124';
  const DATE = '2022-07-15';
  const [weatherData, setWeatherData] = React.useState<WeatherData | null>(
    null,
  );
  const [eventInfo, setEventInfo] = React.useState<ReportInfo>(infosEvent);
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  const [quantidadeData, setQuantidadeData] = React.useState<
    { name: string; value: number }[]
  >([]);
  const [precoData, setPrecoData] = React.useState<
    { name: string; value: number }[]
  >([]);
  const { selectedPartnership, setSelectedPartnership } = useReportsContext();

  const { id } = useParams();

  React.useEffect(() => {
    const event = reportsData.Eventos.find((event) => event.id === id);
    const findPartnership = eventInfo.Informações_Mac_life.find(
      (info) => info.id === event?.Marca,
    );

    setSelectedPartnership(findPartnership || null);
  }, [LAT, LON, API_KEY, DATE, reportsData, eventInfo]);

  React.useEffect(() => {
    const transformChartData = (
      chartData: { [key: string]: number } | undefined,
    ) => {
      if (!chartData) {
        return [];
      }
      return Object.keys(chartData).map((key) => ({
        name: key,
        value: chartData[key],
      }));
    };

    if (selectedPartnership) {
      setQuantidadeData(
        transformChartData(selectedPartnership.Gráficos.Consumo_per_capta),
      );
      setPrecoData(
        transformChartData(
          selectedPartnership.Gráficos.Preço_médio_de_produtos,
        ),
      );
    }
  }, [selectedPartnership]);

  const translateCondition = (condition: string) => {
    return weatherConditionTranslations[condition] || condition;
  };

  return (
    <Stack spacing={2} maxWidth={'100%'}>
      <p>Caracteristicas do evento</p>
      <Stack
        direction="row"
        spacing={2}
        rowGap={'16px'}
        flexWrap={'wrap'}
        justifyContent={'space-evenly'}
      >
        <Box marginLeft="16px !important">
          <PersonalizedCard
            color="red"
            texts={[
              {
                title: 'Tipo do evento',
                description:
                  selectedPartnership?.Caracteristicas_do_Evento
                    .Tipo_do_evento ?? '',
                fontSize: 20,
                fontWeight: 600,
              },
              {
                title: 'Objetivo',
                description:
                  selectedPartnership?.Caracteristicas_do_Evento.Objetivo ?? '',
                fontSize: 20,
                fontWeight: 600,
              },
            ]}
            size="medium"
            icon={<FaCalendarAlt color="white" />}
          />
        </Box>
        <PersonalizedCard
          color="purple"
          texts={[
            {
              title: 'Local',
              description:
                selectedPartnership?.Caracteristicas_do_Evento.Local_evento ??
                '',
              fontSize: 20,
              fontWeight: 600,
            },
            {
              title: 'Bairro',
              description:
                selectedPartnership?.Caracteristicas_do_Evento.Bairro_evento ??
                '',
              fontSize: 20,
              fontWeight: 600,
            },
            {
              title: 'Data',
              description:
                selectedPartnership?.Caracteristicas_do_Evento.Data_evento ??
                '',
              fontSize: 20,
              fontWeight: 600,
            },
          ]}
          size="medium"
          icon={<FaLocationDot color="white" />}
        />
        <PersonalizedCard
          color="green"
          texts={[
            {
              title: 'Tempetura no dia ',
              description: `${selectedPartnership?.Caracteristicas_do_Evento.Temperatura_evento}°C`,
              fontSize: 20,
              fontWeight: 600,
            },
            {
              title: 'Condição de temperatura',
              description:
                `${selectedPartnership?.Caracteristicas_do_Evento.Condição_de_temperatura}` ??
                '',
              fontSize: 20,
              fontWeight: 600,
            },
            {
              title: 'Descrição do clima',
              description:
                `${selectedPartnership?.Caracteristicas_do_Evento.Descrição_do_clima}` ??
                '',
              fontSize: 20,
              fontWeight: 600,
            },
          ]}
          size="medium"
          icon={<FaCloud color="white" />}
        />
      </Stack>

      <p>Dados gerais relevantes</p>

      <Stack
        direction="row"
        spacing={2}
        rowGap={'16px'}
        flexWrap={'wrap'}
        justifyContent={'space-evenly'}
      >
        <Box marginLeft="16px !important">
          <PersonalizedCard
            color="red"
            texts={[
              {
                title: 'Ingressos retirados',
                description:
                  `${selectedPartnership?.Dados_Gerais.Ingressos_retirados}` ??
                  '',
                fontSize: 24,
                fontWeight: 600,
              },
              {
                title: 'Pessoas na lista OFF',
                description: `${selectedPartnership?.Dados_Gerais.Ingressos_OFF}`,
                fontSize: 24,
                fontWeight: 600,
              },
              {
                title: 'Ticket médio ingresso',
                description: `R$${selectedPartnership?.Dados_Gerais.Ticket_médio_ingresso}`,
                fontSize: 24,
                fontWeight: 600,
              },
            ]}
            size="medium"
            icon={<FaTicketAlt color="white" />}
          />
        </Box>
        <PersonalizedCard
          color="purple"
          texts={[
            {
              title: 'Ticket médio geral',
              description: `R$${selectedPartnership?.Dados_Gerais.Ticket_médio_vendido}`,
              fontSize: 24,
              fontWeight: 600,
            },
            {
              title: 'Quantidade de produtos consumidos no total',
              description: `${selectedPartnership?.Dados_Gerais.Produtos_vendidos}`,
              fontSize: 24,
              fontWeight: 600,
            },
            ...(selectedPartnership?.marca === 'Jameson'
              ? [
                  {
                    title: 'Produto mais vendido',
                    description: `Corona : 149 unidades`,
                    fontSize: 24,
                    fontWeight: 600,
                  },
                ]
              : []),
          ]}
          size="medium"
          icon={<FaMoneyBillWave color="white" />}
        />
        <PersonalizedCard
          color="green"
          texts={[
            {
              title: 'Infos relevantes',
              description:
                selectedPartnership?.marca === 'Jameson'
                  ? 'Preferem bebidas inovadoras e mistas, como Jameson Tea and Lime. Tendem a consumir bebidas alcoólicas em ambientes sociais e eventos culturais'
                  : `Com um preço médio de R$ 91,38 e uma mediana de R$ 110, pode-se inferir que a maioria dos ingressos foi vendida a preços mais acessíveis.`,
              fontSize: 20,
              fontWeight: 600,
            },
          ]}
          size="medium"
          icon={isMediumScreen ? undefined : <GiAchievement color="white" />}
        />
      </Stack>
      {selectedPartnership?.marca &&
        selectedPartnership.marca !== 'Sneakers do Boy' &&
        selectedPartnership.marca !== 'KTO' && (
          <>
            <p>Consumo da marca</p>

            <Stack
              direction="row"
              spacing={2}
              rowGap={'16px'}
              flexWrap={'wrap'}
              justifyContent={'space-evenly'}
            >
              <Box marginLeft="16px !important">
                <PersonalizedCard
                  color="red"
                  texts={[
                    {
                      title: 'Quantidade de produtos consumidos da marca',
                      description: `${selectedPartnership?.Consumo_Marca.Quantidade_items_marca}`,
                      fontSize: 24,
                      fontWeight: 600,
                    },
                  ]}
                  size="small"
                  icon={<MdProductionQuantityLimits color="white" />}
                />
              </Box>
              <PersonalizedCard
                color="purple"
                texts={[
                  {
                    title: 'Consumo per capita da marca',
                    description: `${selectedPartnership?.Consumo_Marca.Consumo_per_capta_marca}`,
                    fontSize: 24,
                    fontWeight: 600,
                  },
                ]}
                size="small"
                icon={<FaPerson color="white" />}
              />
              <PersonalizedCard
                color="purple"
                texts={[
                  {
                    title: 'Produto mais vendido',
                    description: `${selectedPartnership?.Consumo_Marca.Produto_mais_vendido_marca}`,
                    fontSize: 24,
                    fontWeight: 600,
                  },
                  {
                    title: 'Quantidade',
                    description: `${selectedPartnership?.Consumo_Marca.Quantidade_produto_mais_vendido_marca}`,
                    fontSize: 24,
                    fontWeight: 600,
                  },
                ]}
                size="small"
                icon={<MdOutlinePointOfSale color="white" />}
              />
            </Stack>
          </>
        )}

      {selectedPartnership?.marca &&
        selectedPartnership.marca === 'Jameson' && (
          <>
            <p>Análise de público</p>
            <Stack
              direction="row"
              spacing={2}
              rowGap={'16px'}
              flexWrap={'wrap'}
              justifyContent={'space-evenly'}
            >
              <Box marginLeft="16px !important">
                <PersonalizedCard
                  color="red"
                  texts={[
                    {
                      title: 'Localização (DDDs)',
                      description: `Belo Horizonte (31): 47% \nTriangulo mineiro (34): 15% \nCentro-Oeste Mineiro (37): 15% \nOutros: 23%`,
                      fontSize: 18,
                      fontWeight: 600,
                    },
                  ]}
                  size="small"
                  icon={<FaLocationDot color="white" />}
                />
              </Box>
              <PersonalizedCard
                color="purple"
                texts={[
                  {
                    title: 'Dispositivos Utilizados',
                    description: `iOS: 80% \nOutros : 20%`,
                    fontSize: 24,
                    fontWeight: 600,
                  },
                ]}
                size="small"
                icon={<MdDevices color="white" />}
              />
              <PersonalizedCard
                color="purple"
                texts={[
                  {
                    title: 'Faixa etária',
                    description: `25-35 anos`,
                    fontSize: 20,
                    fontWeight: 600,
                  },
                  {
                    title: 'Interesses',
                    description: `Tecnologia, inovação, gastronomia, vida noturna e música ao vivo.`,
                    fontSize: 18,
                    fontWeight: 600,
                  },
                ]}
                size="small"
                icon={<MdOutlinePointOfSale color="white" />}
              />
            </Stack>
          </>
        )}
      {selectedPartnership?.marca &&
        selectedPartnership.marca !== 'Sneakers do Boy' &&
        selectedPartnership.marca !== 'KTO' &&
        selectedPartnership.marca !== 'Papelito' && (
          <>
            <p>Análise de consumo</p>
            <Stack
              direction="row"
              spacing={2}
              rowGap={'16px'}
              flexWrap={'wrap'}
              justifyContent={'space-evenly'}
            >
              {isMediumScreen ? (
                <PersonalizedCard
                  color="purple"
                  texts={[
                    {
                      title: 'Consumo per capita da marca',
                      description: '',
                      fontSize: 36,
                      fontWeight: 600,
                    },
                  ]}
                  size="large"
                  icon={<FaChartArea color="white" />}
                  attachment={
                    <Chart
                      infoToBeShown={selectedPartnership?.marca ?? ''}
                      data={quantidadeData}
                    />
                  }
                />
              ) : (
                <>
                  <p className={styles.chartTitle}>
                    Consumo per capita da marca
                  </p>
                  <Chart
                    infoToBeShown={selectedPartnership?.marca ?? ''}
                    data={quantidadeData}
                  />
                </>
              )}

              {isMediumScreen ? (
                <PersonalizedCard
                  color="purple"
                  texts={[
                    {
                      title: 'Preço médio de produtos',
                      description: '',
                      fontSize: 36,
                      fontWeight: 600,
                    },
                  ]}
                  size="large"
                  icon={<FaChartArea color="white" />}
                  attachment={
                    <Chart
                      infoToBeShown={selectedPartnership?.marca ?? ''}
                      data={precoData}
                      formatter="money"
                    />
                  }
                />
              ) : (
                <>
                  <p className={styles.chartTitle}>Preço médio de produtos</p>
                  <Chart
                    infoToBeShown={selectedPartnership?.marca ?? ''}
                    data={precoData}
                    formatter="money"
                  />
                </>
              )}
            </Stack>
          </>
        )}
    </Stack>
  );
}
