import { Box, Stack } from "@mui/material";
import React from "react";
import { TiTick } from "react-icons/ti";
import styles from "./index.module.scss";

type Stage = {
  title: string;
  icon: React.ReactNode;
};

type Props = {
  stages: Stage[];
  selectedStage: string;
};

export default function MultistepProgress({ stages, selectedStage }: Props) {
  const selectedIndex = stages.findIndex(
    (stage) => stage.title === selectedStage
  );

  return (
    <div>
      <Stack direction={"row"}>
        {stages.map((stage, index) => {
          const isSelected = index <= selectedIndex;

          return (
            <Box className={styles.multiStepWrapper} key={index}>
              {index !== 0 && (
                <Box
                  className={isSelected ? styles.selectedLine : styles.line}
                ></Box>
              )}
              <Box className={styles.stepWrapper}>
                <Box className={styles.stepIcon}>{stage.icon}</Box>
                <Box
                  className={`${
                    isSelected ? styles.selectedStepDisplay : styles.stepDisplay
                  }`}
                >
                  {isSelected ? (
                    <TiTick className={styles.displayIcon} />
                  ) : (
                    <p>{index}</p>
                  )}
                </Box>
                <Box className={styles.stepTitle}>{stage.title}</Box>
              </Box>
            </Box>
          );
        })}
      </Stack>
    </div>
  );
}
