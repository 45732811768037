import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import styles from "./index.module.scss";
import MenuSideBar from "../../components/menuSideBar";

type Props = {
  children: React.ReactNode;
};

function DashboardLayout({ children }: Props) {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Box
      className={
        isMediumScreen
          ? styles.dashboardLayoutWrapper
          : styles.mobileLayoutWrapper
      }
    >
      <Grid container height={"100%"} width={"100%"}>
        {isMediumScreen && (
          <Grid item xs={1.5}>
            <MenuSideBar />
          </Grid>
        )}

        <Grid item xs={isMediumScreen ? 10.5 : 12}>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
}

export default DashboardLayout;
