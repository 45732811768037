import React from "react";
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import styles from "./index.module.scss";
import { getIconByArea } from "../../utils/iconsByArea";
import MenuItem from "./menuitem";
import MenuHeader from "./menuHeader";
import { useMenuContext } from "../../hooks/MenuContext";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";

export default function MenuSideBar() {
  const areas = [
    "Home",
    "Contratos",
    "Parcerias",
    "Relatórios",
    "Notificações",
  ];
  const { selectedArea } = useMenuContext();
  const navigate = useNavigate();

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));

  return (
    <Stack spacing={2} className={styles.menuSideBarWrapper}>
      <MenuHeader />
      <Box className={styles.line}></Box>
      {areas.map((area, index) => (
        <MenuItem
          key={index}
          index={index}
          area={area}
          iconFontSize={25}
          selected={area === selectedArea}
        />
      ))}
      {isLargeScreen && (
        <Box className={`${styles.menuTitle} ${styles.menuItem}`}>
          Área do usuário
        </Box>
      )}
      <MenuItem
        index={6}
        area={"Perfil"}
        iconFontSize={25}
        selected={"Perfil" === selectedArea}
      />
      <MenuItem
        index={7}
        area={"Configurações"}
        iconFontSize={25}
        selected={"Configurações" === selectedArea}
      />
      <MenuItem
        index={8}
        area={"Logout"}
        iconFontSize={25}
        selected={"Logout" === selectedArea}
        onClick={() => {
          signOut(auth)
            .then(() => {
              console.log("Logout bem-sucedido!");
              navigate("/");
            })
            .catch((error) => {
              console.error("Erro ao fazer logout:", error);
            });
        }}
      />
    </Stack>
  );
}
