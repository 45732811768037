import { Grid, Stack } from '@mui/material';
import React from 'react';
import { FaExpandAlt } from 'react-icons/fa';
import { MdModeEdit } from 'react-icons/md';
import { MdDelete } from 'react-icons/md';
import styles from './extra.module.scss';
import { IoAddOutline } from 'react-icons/io5';
import { PartnershipCreate } from '../dtos';

type Props = {
  title?: string;
  date?: string;
  add: boolean;
  setOpenCreate: (value: boolean) => void;
  setPartnershipCreateInfo: (value: PartnershipCreate) => void;
  partnershipCreateInfo: PartnershipCreate;
};

function CounterpartButton({
  title,
  date,
  add,
  setOpenCreate,
  setPartnershipCreateInfo,
  partnershipCreateInfo,
}: Props) {
  const handleDelete = () => {
    if (title) {
      const updatedTasks = partnershipCreateInfo.tasks.filter(
        (task) => task.title !== title,
      );
      setPartnershipCreateInfo({
        ...partnershipCreateInfo,
        tasks: updatedTasks,
      });
    }
  };

  return (
    <Grid
      container
      className={`${add ? styles.addButton : styles.counterPartWrapper}`}
      onClick={() => {
        if (add) {
          setOpenCreate(true);
        }
      }}
    >
      <Grid item xs={6}>
        <p>{!add ? title : 'Criar nova contrapartida'}</p>
      </Grid>
      <Grid item xs={3}>
        <p>{date}</p>
      </Grid>
      <Grid item xs={3} alignItems={'center'}>
        <Stack
          direction={'row'}
          spacing={2}
          justifyContent={'flex-end'}
          alignItems={'center'}
          height={'100%'}
        >
          {!add ? (
            <>
              <FaExpandAlt className={styles.icon} />
              <MdModeEdit className={styles.icon} />
              <MdDelete className={styles.remove} onClick={handleDelete} />
            </>
          ) : (
            <IoAddOutline className={styles.icon} />
          )}
        </Stack>
      </Grid>
    </Grid>
  );
}

export default CounterpartButton;
