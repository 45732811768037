import { Box } from "@mui/material";
import { Dropdown, MenuProps } from "antd";
import React from "react";
import styles from "./index.module.scss";

type Props = {
  items: MenuProps["items"];
  value: string;
  setValue: (value: string) => void;
};

export default function PersonalizedDropDown({
  items,
  value,
  setValue,
}: Props) {
  const [open, setOpen] = React.useState<boolean>(false);

  const onClick: MenuProps["onClick"] = ({ key }) => {
    setValue(key);
    setOpen(true);
  };

  const handleOpenChange = (flag: boolean) => {
    setOpen(flag);
  };

  return (
    <Dropdown
      menu={{ items, onClick }}
      overlayStyle={{ minWidth: "200px", padding: "8px" }}
      open={open}
      onOpenChange={handleOpenChange}
    >
      <a onClick={(e) => e.preventDefault()}>
        <Box minWidth={"200px"} className={styles.text}>
          {value}
        </Box>
      </a>
    </Dropdown>
  );
}
