import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import styles from './index.module.scss';

interface RadioOption {
  value: string;
  label: string;
}

interface PersonalizedRadioGroupProps {
  label: string;
  name: string;
  options: RadioOption[];
  defaultValue?: string;
  onChange: (value: string) => void;
}

export default function PersonalizedRadioGroup({
  label,
  name,
  options,
  defaultValue = '',
  onChange,
}: PersonalizedRadioGroupProps) {
  const [value, setValue] = React.useState(defaultValue);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = (event.target as HTMLInputElement).value;
    setValue(selectedValue);
    onChange(selectedValue);
  };

  return (
    <FormControl>
      <FormLabel style={{ color: 'white' }}>{label}</FormLabel>
      <RadioGroup row name={name} value={value} onChange={handleChange}>
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio style={{ color: 'white' }} />}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
