import React from "react";
import { BiSolidDashboard } from "react-icons/bi";
import { FaFileContract } from "react-icons/fa6";
import { MdPermMedia, MdNotificationsActive } from "react-icons/md";
import { ImProfile } from "react-icons/im";
import { IoSettingsSharp } from "react-icons/io5";
import { RiLogoutBoxFill } from "react-icons/ri";
import { TbReportSearch } from "react-icons/tb";
import Profile from "../areas/Profile";
import Reports from "../areas/Reports";
import ReceivingArea from "../areas/ReceivingArea";
import Characteristics from "../areas/Reports/ReportDetails/Characteristics";
import { PiHandshake } from "react-icons/pi";
import Partnership from "../areas/Partnership";
import { ReportsData } from "../areas/Reports/dtos";

const withFontSize =
  (IconComponent: React.ComponentType<any>, fontSize: number) =>
  (props: any) => {
    return <IconComponent style={{ fontSize: `${fontSize}px` }} {...props} />;
  };

export function getIconByArea(area: string, fontSize: number) {
  const icons: { [key: string]: React.ComponentType<any> } = {
    Home: BiSolidDashboard,
    Contratos: FaFileContract,
    Entregas: MdPermMedia,
    Parcerias: PiHandshake,
    Relatórios: TbReportSearch,
    Notificações: MdNotificationsActive,
    Perfil: ImProfile,
    Configurações: IoSettingsSharp,
    Logout: RiLogoutBoxFill,
  };

  const IconComponent = icons[area];

  if (!IconComponent) return null;

  const IconWithFontSize = withFontSize(IconComponent, fontSize);
  return <IconWithFontSize />;
}

export function componentMap(key: string) {
  switch (key) {
    case "Relatórios":
      return <Reports />;
    case "Perfil":
      return <Profile />;
    case "Parcerias":
      return <Partnership />;
    case "Home":
      return <ReceivingArea></ReceivingArea>;
  }
}

export function reportMap(key: string, reportsData: ReportsData) {
  switch (key) {
    case "Características":
      return <Characteristics reportsData={reportsData} />;
    case "Perfil":
      return <Profile />;
    case "Home":
      return <ReceivingArea></ReceivingArea>;
  }
}
