import { Stack } from "@mui/material";
import React from "react";
import styles from "./index.module.scss";
import FilterSelector from "./FilterSelector";
import { FiltersContext } from "../../hooks/FiltersContext";

function Filters() {
  const [timeInterval, setTimeInterval] = React.useState<Date[]>([
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
    new Date(),
  ]);
  const [status, setStatus] = React.useState<string>("Todos os status");
  const [city, setCity] = React.useState<string>("Belo Horizonte");

  return (
    <Stack className={styles.dropDownFilters}>
      <FiltersContext.Provider
        value={{
          timeInterval,
          setTimeInterval,
          status,
          setStatus,
          city,
          setCity,
        }}
      >
        <FilterSelector />
      </FiltersContext.Provider>
    </Stack>
  );
}

export default Filters;
