export enum UserType {
    Brand = "Brand",
    Producer = "Producer"
}

export type User = {
    username: string;
    email: string;
    password: string;
    userType: UserType;
} 

export type SuccessType = {
    message: string;
    isSuccess: boolean;
  };