import React from 'react';
import IconSelector from './iconSelector';
import { Stack } from '@mui/material';
import styles from './index.module.scss';

type Props = {
  options: string[];
  selectedIcons: string[];
  setSelectedIcons: (icons: string[]) => void;
};

export default function PersonalizedSelectorIcon({
  options,
  selectedIcons,
  setSelectedIcons,
}: Props) {
  const handleSelectIcon = (icon: string) => {
    if (selectedIcons.includes(icon)) {
      setSelectedIcons(selectedIcons.filter((i) => i !== icon));
    } else {
      setSelectedIcons([...selectedIcons, icon]);
    }
  };

  return (
    <Stack
      direction="row"
      flexWrap={'wrap'}
      spacing={2}
      className={styles.selectorIconWrapper}
    >
      {options.map((item, index) => (
        <IconSelector
          key={index}
          text={item}
          selectedIcons={selectedIcons}
          handleSelectIcon={handleSelectIcon}
        />
      ))}
    </Stack>
  );
}
