import React from "react";
import styles from "./index.module.scss";
import { useMenuContext } from "../../hooks/MenuContext";
import { Box, Stack } from "@mui/material";
import defaultProfile from "../../resources/default_profile.png";
import DataTable from "../../components/dataTable";

export default function ProfileCard() {
  const { user } = useMenuContext();

  const info = {
    "Numero de celular": "31998652604",
    "Endereco de email": user && user.email ? user.email : "",
    Empresa: "Sponsorize",
  };

  return (
    <Stack direction="column" width={"80%"} className={styles.profileWrapper}>
      <Box className={styles.profileSummary}>
        <img
          src={user && user.photoURL ? user.photoURL : defaultProfile}
          width={"128px"}
          style={{ borderRadius: "50%" }}
        ></img>
        <p> {user?.displayName}</p>
        <p> Sponsorize</p>
      </Box>
      <Box className={styles.dataTableWrapper}>
        <Box className={styles.dataTableSizer}>
          <DataTable data={info} />
        </Box>
      </Box>
    </Stack>
  );
}
