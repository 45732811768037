import React from "react";
import Dot from "./dot";
import { Box, Stack } from "@mui/material";
import styles from "./ImageDots.module.scss";

type Props = {
  medias: string[];
  positionDisplay: number;
};

function ImageDots({ positionDisplay, medias }: Props) {
  const [isPulsing, setIsPulsing] = React.useState(false);

  React.useEffect(() => {
    setIsPulsing(true);

    const timeout = setTimeout(() => {
      setIsPulsing(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [positionDisplay]);

  return (
    <Stack direction="row" spacing={1} className={styles.imageDotsWrapper}>
      <Box
        className={`${styles.greenDot} ${isPulsing ? styles.pulse : ""}`}
        sx={{
          transform: `translate(calc(50% + ${positionDisplay * 24}px), 0)`,
        }}
      ></Box>
      {medias.map((__, index) => {
        return <Dot selected={positionDisplay === index} />;
      })}
    </Stack>
  );
}

export default ImageDots;
