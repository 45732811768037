import React from "react";
import styles from "./index.module.scss";
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import Breadcrumb from "../../../components/breadCrumb/breadcrumb";
import { BsFillPeopleFill } from "react-icons/bs";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { MdOutlineTipsAndUpdates } from "react-icons/md";
import { PiHandshake } from "react-icons/pi";
import { TbReportSearch } from "react-icons/tb";
import { reportMap } from "../../../utils/iconsByArea";
import DeliveriesButton from "./Deliveries/DeliveriesButton";
import { InformacoesMacLife, ReportsData } from "../dtos";
import Deliveries from "./Deliveries";
import { ReportsContext } from "../../../hooks/ReportsContext";
import DeliveriesMobile from "./Deliveries/ModalMobile";
import PersonalizedButton from "../../../components/personalizedButton";
import { IoMdArrowRoundBack } from "react-icons/io";

type Props = {
  reportsData: ReportsData | null;
  setReportsData: (reportsData: ReportsData | null) => void;
  setDetailsId: (detailsId: string | null) => void;
};

export default function ReportDetails({
  reportsData,
  setReportsData,
  setDetailsId,
}: Props) {
  const [selectedSession, setSelectedSession] =
    React.useState<string>("Características");
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const [isDeliveriesOpen, setIsDeliveriesOpen] =
    React.useState<boolean>(false);
  const [selectedPartnership, setSelectedPartnership] =
    React.useState<InformacoesMacLife | null>(null);
  return (
    <ReportsContext.Provider
      value={{
        reportsData,
        setReportsData,
        selectedPartnership,
        setSelectedPartnership,
      }}
    >
      <Box className={styles.reportDetailsWrapper}>
        {isDeliveriesOpen &&
          (isLargeScreen ? (
            <Deliveries
              isOpen={isDeliveriesOpen}
              setIsOpen={setIsDeliveriesOpen}
            />
          ) : (
            <DeliveriesMobile
              isOpen={isDeliveriesOpen}
              setIsOpen={setIsDeliveriesOpen}
            />
          ))}
        <Stack direction="column" spacing={2}>
          <Stack
            direction={isMediumScreen ? "row" : "column"}
            justifyContent={"space-between"}
            alignItems={!isMediumScreen ? "center" : undefined}
            spacing={2}
          >
            <Stack direction={"row"} spacing={2}>
              <Box width={"150px"} height={"40px"}>
                <PersonalizedButton
                  variant="fourth"
                  text="Voltar"
                  onClick={() => {
                    setDetailsId(null);
                  }}
                  icon={<IoMdArrowRoundBack />}
                />
              </Box>
              {isMediumScreen && (
                <Breadcrumb
                  sessions={{
                    Público: <BsFillPeopleFill />,
                    Consumo: <FaMoneyBillTrendUp />,
                    Insights: <MdOutlineTipsAndUpdates />,
                    Parcerias: <PiHandshake />,
                    Características: <TbReportSearch />,
                  }}
                  selectedSession={selectedSession}
                  setSelectedSession={setSelectedSession}
                />
              )}
            </Stack>
            <DeliveriesButton
              onClick={() => {
                setIsDeliveriesOpen(true);
              }}
            />
          </Stack>
          {reportsData && reportMap(selectedSession, reportsData)}
        </Stack>
      </Box>
    </ReportsContext.Provider>
  );
}
