import { Box, Stack } from "@mui/material";
import React from "react";
import { FaHistory } from "react-icons/fa";
import styles from "./index.module.scss";
import { useReportsContext } from "../../../../hooks/ReportsContext";

type Props = {
  onClick: () => void;
};

function DeliveriesButton({ onClick }: Props) {
  const { selectedPartnership } = useReportsContext();
  const [completionPercentage, setCompletionPercentage] =
    React.useState<number>();

  React.useEffect(() => {
    if (selectedPartnership) {
      const completedDeliveries = selectedPartnership.Entregas.filter(
        (entrega) => entrega.Status === "Concluído"
      );
      const totalDeliveries = selectedPartnership.Entregas.length;
      setCompletionPercentage(
        (completedDeliveries.length / totalDeliveries) * 100 || 0
      );
    }
  }, [selectedPartnership]);

  return (
    <Box className={styles.buttonWrapper} onClick={onClick}>
      <Stack direction="row" className={styles.upper} spacing={1}>
        <Box>Visualizar entregas</Box>
        <FaHistory className={styles.icon} fontSize="28px" />
      </Stack>

      <Box className={styles.lower}>
        {completionPercentage?.toFixed(0)}% concluídas
      </Box>
    </Box>
  );
}

export default DeliveriesButton;
