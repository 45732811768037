import React from 'react';
import DashboardLayout from '../../layout/dashBoardLayout';
import { MenuContext } from '../../hooks/MenuContext';
import { Box } from '@mui/material';
import styles from './index.module.scss';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../firebase';
import { User as FirebaseUser } from 'firebase/auth';
import { componentMap } from '../../utils/iconsByArea';

function Home() {
  const [selectedArea, setSelectedArea] = React.useState<string>('Home');
  const [user, setUser] = React.useState<FirebaseUser | null>(null);

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        const uid = user.email;
        console.log('uid', uid);
        console.log(user);
      } else {
        console.log('user is logged out');
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <Box className={styles.dashboardWrapper}>
      <MenuContext.Provider
        value={{ selectedArea, setSelectedArea, user, setUser }}
      >
        <DashboardLayout>
          <Box className={styles.contentWrapper}>
            {componentMap(selectedArea)}
          </Box>
        </DashboardLayout>
      </MenuContext.Provider>
    </Box>
  );
}

export default Home;
