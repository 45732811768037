import React from "react";
import EditForms from "./editForms";
import styles from "../index.module.scss";
import { Stack } from "@mui/material";

export default function EditMenu() {
  return (
    <Stack direction={"column"} spacing={2} sx={{ marginTop: "8px" }}>
      <p className={styles.sectionTitle}> Editar configurações</p>
      <EditForms />
    </Stack>
  );
}
