import { Box } from "@mui/material";
import React from "react";
import styles from "./index.module.scss";

type Props = {
  children: React.ReactNode;
  icon: React.ReactNode;
  label: string;
  onClick?: () => void;
};

function FilterButton({ children, icon, label, onClick }: Props) {
  return (
    <Box
      className={styles.filterButton}
      data-text={label}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      {children}
      {icon}
    </Box>
  );
}

export default FilterButton;
