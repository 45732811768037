import React from "react";
import { Box, Stack } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";
import styles from "./index.module.scss";

type Sessions = {
  [key: string]: React.ReactNode;
};

type Props = {
  sessions: Sessions;
  selectedSession: string;
  setSelectedSession: (session: string) => void;
};

export default function Breadcrumb({
  sessions,
  selectedSession,
  setSelectedSession,
}: Props) {
  return (
    <Stack direction="row" spacing={2}>
      {Object.entries(sessions).map(([session, icon], index) => (
        <Box
          key={index}
          className={`${
            selectedSession === session
              ? styles.breadcrumbItemSelected
              : styles.breadcrumbItem
          }`}
          onClick={() => {
            setSelectedSession(session);
          }}
          component={motion.div}
          layout
          transition={{ duration: 0.5 }}
        >
          <Stack
            direction={"row"}
            spacing={1}
            alignItems={"center"}
            justifyContent={"center"}
          >
            {icon}
            <AnimatePresence>
              {selectedSession === session && (
                <motion.p
                  initial={{ opacity: 0, x: -10 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -10 }}
                  transition={{ duration: 0.2, type: "tween" }}
                >
                  {session}
                </motion.p>
              )}
            </AnimatePresence>
          </Stack>
        </Box>
      ))}
    </Stack>
  );
}
