import { Box } from "@mui/material";
import React from "react";
import video from "../../../resources/loginVideo.mp4";
import video2 from "../../../resources/loginVideo2.mp4";
import video3 from "../../../resources/loginVideo3.mp4";
import styles from "./ImageDisplay.module.scss";
import ImageDots from "./imageDots/ImageDots";
import { TypeAnimation } from "react-type-animation";

function ImageDisplay() {
  const [medias, setMedias] = React.useState<string[]>([video, video2, video3]);
  const [positionDisplay, setPositionDisplay] = React.useState(0);
  const appearPhrases = [
    "Estamos revolucionando a relação entre marcas e produtores!",
    "Venha mudar o mercado do entreterimento conosco!",
    "Faça já o seu cadastro na plataforma!",
  ];

  React.useEffect(() => {
    const interval = setInterval(() => {
      setPositionDisplay((prevPosition) => (prevPosition + 1) % 3);
    }, 8000);

    return () => clearInterval(interval);
  }, []);

  const getZIndex = (videoIndex: number, positionDisplay: number) => {
    if (positionDisplay === 0) {
      return videoIndex === 0 ? 3 : videoIndex === 1 ? 2 : 1;
    } else if (positionDisplay === 1) {
      return videoIndex === 1 ? 3 : videoIndex === 2 ? 2 : 1;
    } else {
      return videoIndex === 2 ? 3 : videoIndex === 0 ? 2 : 1;
    }
  };

  return (
    <Box className={styles.imageDisplayWrapper}>
      {(positionDisplay === 0 || positionDisplay === 1) && (
        <video
          className={styles.video0}
          style={{ zIndex: getZIndex(0, positionDisplay) }}
          autoPlay
          muted
          loop
        >
          <source src={medias[0]} type="video/mp4" />
        </video>
      )}
      {(positionDisplay === 1 || positionDisplay === 2) && (
        <video
          className={styles.video1}
          style={{ zIndex: getZIndex(1, positionDisplay) }}
          autoPlay
          muted
          loop
        >
          <source src={medias[1]} type="video/mp4" />
        </video>
      )}
      {(positionDisplay === 2 || positionDisplay === 0) && (
        <video
          className={styles.video2}
          style={{ zIndex: getZIndex(2, positionDisplay) }}
          autoPlay
          muted
          loop
        >
          <source src={medias[2]} type="video/mp4" />
        </video>
      )}
      <ImageDots medias={medias} positionDisplay={positionDisplay} />
      <TypeAnimation
        sequence={[
          appearPhrases[0],
          4000,
          appearPhrases[1],
          3000,
          appearPhrases[2],
          3000,
        ]}
        speed={50}
        className={styles.text}
        repeat={Infinity}
      />
    </Box>
  );
}

export default ImageDisplay;
