import React from "react";
import styles from "./index.module.scss";
import { Box } from "@mui/material";

type Props = {
  text: string;
  checkboxValue: boolean;
  setCheckBoxValue: (value: boolean) => void;
  id?: string;
};

function PersonalizedCheckBox({
  id,
  text,
  checkboxValue,
  setCheckBoxValue,
}: Props) {
  return (
    <Box className={styles.checkboxWrapper}>
      <input
        id={id ? id : "one"}
        type="checkbox"
        checked={checkboxValue}
        onClick={() => setCheckBoxValue(!checkboxValue)}
      />
      <label htmlFor={id}>
        <span></span>
        {text}
        <ins>
          <i>{text}</i>
        </ins>
      </label>
    </Box>
  );
}

export default PersonalizedCheckBox;
