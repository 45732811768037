import React from "react";
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import styles from "./index.module.scss";
import { getIconByArea } from "../../utils/iconsByArea";
import { useMenuContext } from "../../hooks/MenuContext";

type Props = {
  index: number;
  area: string;
  iconFontSize: number;
  selected: boolean;
  onClick?: () => void;
};

export default function MenuItem({
  index,
  area,
  iconFontSize,
  selected,
  onClick,
}: Props) {
  const { setSelectedArea } = useMenuContext();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems={"center"}
      key={index}
      className={`${styles.menuItem} ${
        selected ? styles.selected : styles.notSelected
      }`}
      sx={{ width: isLargeScreen ? "calc(100% - 64px)" : undefined }}
      onClick={() => {
        setSelectedArea(area);
        if (onClick) {
          onClick();
        }
      }}
    >
      <>{getIconByArea(area, iconFontSize)}</>
      {isLargeScreen && (
        <Box sx={{ fontSize: `${isLargeScreen ? iconFontSize - 8 : 40}px` }}>
          {area}
        </Box>
      )}
    </Stack>
  );
}
