import { Box, Stack } from "@mui/material";
import React from "react";
import styles from "./index.module.scss";

type Props = {
  title: string;
  text?: string;
  badges?: string[];
};

export default function InfoGenerator({ title, text, badges }: Props) {
  return (
    <Stack direction="column" spacing={0}>
      <p className={styles.title}>{title}</p>
      {badges ? (
        <Stack
          direction={"row"}
          spacing={1}
          marginTop="4px"
          sx={{ maxWidth: "80%", flexWrap: "wrap", gap: "8px" }}
        >
          {badges.map((value) => (
            <Box className={styles.badge}> {value} </Box>
          ))}
        </Stack>
      ) : (
        <p className={styles.text}>{text}</p>
      )}
    </Stack>
  );
}
