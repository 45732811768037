import React from "react";
import { DateRangePicker, RangeKeyDict } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

type Props = {
  timeInterval: Date[];
  setTimeInterval: (value: Date[]) => void;
};

export default function PersonalizedDateRangePicker({
  timeInterval,
  setTimeInterval,
}: Props) {
  const selectionRange = {
    startDate: timeInterval[0],
    endDate: timeInterval[1],
    key: "selection",
  };

  function handleSelect(ranges: RangeKeyDict) {
    const { selection } = ranges;
    if (selection.startDate && selection.endDate) {
      setTimeInterval([selection.startDate, selection.endDate]);
    }
  }

  return <DateRangePicker ranges={[selectionRange]} onChange={handleSelect} />;
}
