import React from "react";
import styles from "./index.module.scss";
import { Box } from "@mui/material";
type Props = {
  text: string;
  onClick: () => void;
  variant: string;
  icon?: React.ReactNode;
  disabled?: boolean;
};

function PersonalizedButton({
  text,
  onClick,
  variant,
  icon,
  disabled = false,
}: Props) {
  return (
    <Box
      className={`${styles.buttonWrapper} ${
        !disabled ? styles[variant] : styles.disabled
      }`}
      onClick={!disabled ? onClick : () => {}}
    >
      <p>{text}</p>
      {icon && icon}
    </Box>
  );
}

export default PersonalizedButton;
