import React from 'react';
import { useMenuContext } from '../../hooks/MenuContext';
import styles from './index.module.scss';

export default function ReceivingArea() {
  const { user } = useMenuContext();
  return (
    <div>
      <p className={styles.greetings}>Bem vindo, {user?.displayName}</p>
    </div>
  );
}
