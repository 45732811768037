import { Stack } from '@mui/material';
import React from 'react';
import PersonalizedInput from '../../../components/personalizedInput';
import PersonalizedButtonDatePicker from '../../../components/personalizedButtonDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { NewCounterPart, NewTask } from '../dtos';

type Props = {
  newCounterPart: NewCounterPart;
  setNewCounterPart: (counterPart: NewCounterPart) => void;
  newTask: NewTask;
  setNewTask: (task: NewTask) => void;
  type: string;
};

function FirstCounterPart({
  newCounterPart,
  setNewCounterPart,
  newTask,
  setNewTask,
  type,
}: Props) {
  const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(() =>
    dayjs(new Date()),
  );

  const handleInputChange = (
    field: keyof NewTask | keyof NewCounterPart,
    value: any,
  ) => {
    if (type === 'CounterPart') {
      setNewCounterPart({
        ...newCounterPart,
        [field]: value,
      });
    } else {
      setNewTask({
        ...newTask,
        [field]: value,
      });
    }
  };

  return (
    <Stack spacing={3}>
      <p style={{ fontWeight: 600 }}>
        Primeiro vamos começar inserindo algumas informações básicas sobre a
        contrapartida. Todas as informações dessa página são obrigatórios de
        serem preenchidas.
      </p>
      <PersonalizedInput
        text="Insira o título da entrega"
        variant="transparent-black"
        setStoredText={(value) => handleInputChange('title', value)}
        inputType="text"
      />
      <PersonalizedInput
        text="Email do responsável"
        variant="transparent-black"
        setStoredText={(value) => handleInputChange('responsible_email', value)}
        inputType="text"
      />
      <Stack spacing={1}>
        <p style={{ fontWeight: 600 }}>
          Selecione a data limite para a entrega dessa Contrapartida
        </p>
        <PersonalizedButtonDatePicker
          date={selectedDate}
          setDate={(date) => {
            setSelectedDate(date);
            handleInputChange('due_date', date);
          }}
        />
      </Stack>
    </Stack>
  );
}

export default FirstCounterPart;
