import { Box, Stack } from '@mui/material';
import React from 'react';
import PersonalizedCheckBox from '../../../components/personalizedCheckBox';
import PersonalizedInput from '../../../components/personalizedInput';
import styles from '../index.module.scss';
import { apiRequest } from '../../../api/apiUtils';
import { PartnershipCreate } from '../dtos';

async function createPartnership(newPartnership: PartnershipCreate) {
  await apiRequest({
    endpoint: '/create_partnership/',
    method: 'POST',
    data: newPartnership,
  });
}

type Props = {
  setPartnershipCreateInfo: (value: PartnershipCreate) => void;
  partnershipCreateInfo: PartnershipCreate;
};

function Criação({ setPartnershipCreateInfo, partnershipCreateInfo }: Props) {
  const [isMarcaSelected, setIsMarcaSelected] = React.useState<boolean>(false);
  const [isProdutorSelected, setIsProdutorSelected] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    if (isMarcaSelected) {
      setIsProdutorSelected(false);
    }
  }, [isMarcaSelected]);

  React.useEffect(() => {
    if (isProdutorSelected) {
      setIsMarcaSelected(false);
    }
  }, [isProdutorSelected]);

  const handleInputChange = (field: keyof PartnershipCreate, value: string) => {
    setPartnershipCreateInfo({
      ...partnershipCreateInfo,
      [field]: value,
    });
    console.log(partnershipCreateInfo);
  };

  return (
    <Stack width="70%" spacing={2} marginTop="48px" marginBottom={'32px'}>
      <p className={styles.title}>Informações básicas</p>
      <Stack direction="column" className={styles.checkboxesWrapper}>
        <p className={styles.text}>Você é marca ou produtor?</p>
        <Stack direction="row" spacing={2}>
          <PersonalizedCheckBox
            id="checkbox-marca"
            text="Marca"
            setCheckBoxValue={setIsMarcaSelected}
            checkboxValue={isMarcaSelected}
          />
          <PersonalizedCheckBox
            id="checkbox-produtor"
            text="Produtor"
            setCheckBoxValue={setIsProdutorSelected}
            checkboxValue={isProdutorSelected}
          />
        </Stack>
      </Stack>
      <PersonalizedInput
        variant="primary"
        text="Insira o nome do evento"
        inputType="text"
        setStoredText={(value) => handleInputChange('event_name', value)}
      />
      <PersonalizedInput
        variant="primary"
        text="Insira o email da outra parte da parceria"
        inputType="email"
        setStoredText={() => {}}
      />
    </Stack>
  );
}

export default Criação;
