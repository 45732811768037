import { Grid, Stack } from "@mui/material";
import React from "react";
import InfoGenerator from "../infoGenerator";
import styles from "../index.module.scss";
import DataTable from "../../../components/dataTable";

export default function InfoMenu() {
  return (
    <Stack direction={"column"} spacing={2} sx={{ marginTop: "8px" }}>
      <p className={styles.sectionTitle}> Perfil da empresa</p>
      <Grid container>
        <Grid item xs={6}>
          <Stack direction="column" spacing={2}>
            <InfoGenerator title="Empresa" text="Sponsorize" />
            <InfoGenerator title="Plano Atual" text="Plano básico" />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <InfoGenerator
            title="Numero de parcerias"
            text="14 parcerias ativas, 100 no registro"
          />
          <InfoGenerator
            title="Marcas/produtores parceiros"
            badges={[
              "Redbull",
              "Equilibrista",
              "Jameson",
              "Heineken",
              "Redbull",
              "Equilibrista",
              "Jameson",
              "Heineken",
            ]}
          />
        </Grid>
      </Grid>
      <p className={styles.sectionTitle}> Atividade recente</p>
      <DataTable
        data={{
          "10/07/2024 13:00": "MacLife criou uma parceria com a Redbull",
          "10/07/2024 13:01": "MacLife criou uma parceria com a Redbull",
          "10/07/2024 13:02": "MacLife criou uma parceria com a Redbull",
        }}
      />
    </Stack>
  );
}
