import React from "react";
import DashboardLayout from "../../layout/dashBoardLayout";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import styles from "./index.module.scss";
import stylesMobile from "./mobile.module.scss";
import Reports from "../Reports";

export default function BetaReportArea() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box
      className={
        isMobile ? styles.dashboardWrapper : stylesMobile.dashboardWrapper
      }
    >
      <DashboardLayout>
        <Box
          className={
            isMobile ? styles.contentWrapper : stylesMobile.contentWrapper
          }
        >
          <Reports />
        </Box>
      </DashboardLayout>
    </Box>
  );
}
