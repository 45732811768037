import { Box, Typography } from "@mui/material";
import React from "react";
import styles from "./index.module.scss";

type Props = {
  inputType: string;
  text: string;
  variant: string;
  icon?: React.ReactNode;
  setStoredText?: (text: string) => void;
  placeholder?: string;
};

function PersonalizedInput({
  inputType,
  text,
  variant,
  icon,
  placeholder,
  setStoredText,
}: Props) {
  const [isClicked, setIsClicked] = React.useState<boolean>(false);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target as Node)
    ) {
      if (inputRef.current && inputRef.current.value !== "") {
        setIsClicked(true);
      } else {
        setIsClicked(false);
      }
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Box
      ref={wrapperRef}
      className={`${styles.buttonWrapper} ${isClicked && styles.clickedColor} ${
        styles[variant]
      }`}
      onClick={() => {
        setIsClicked(true);
      }}
    >
      <Typography
        className={`${styles.body} ${
          (placeholder || isClicked) && styles.clicked
        }`}
      >
        {text}
      </Typography>
      <input
        ref={inputRef}
        type={inputType === "password" && showPassword ? "text" : inputType}
        onChange={(event) => {
          setIsClicked(true)
          if (setStoredText) {
            setStoredText(event.target.value);
          }
        }}
        placeholder={placeholder}
      />
      {
        <div
          onClick={() => {
            if (inputType === "password") {
              setShowPassword(!showPassword);
            }
          }}
        >
          {icon}
        </div>
      }
    </Box>
  );
}

export default PersonalizedInput;
