import { Stack } from '@mui/material';
import React from 'react';
import styles from './extra.module.scss';
import PartnershipPreview from './PartnershipPreview';
import StatusBar from './StatusBar';

function PartnershipList() {
  const parceria: { [key: string]: string } = {
    'Parceria CocaCola': 'Requer aceite',
    'Parceria Heineken': 'Enviado',
    'Parceria XequeMate': 'Assinado',
  };
  return (
    <Stack direction="column">
      <p className={styles.categoriesTitle}>Lista das parcerias</p>
      <StatusBar />
      <Stack
        direction="row"
        flexWrap={'wrap'}
        rowGap={2}
        columnGap={2}
        justifyContent={'space-evenly'}
      >
        {Object.keys(parceria).map((title: string, index: number) => {
          return (
            <PartnershipPreview
              title={title}
              status={parceria[title]}
              index={index}
            />
          );
        })}
      </Stack>
    </Stack>
  );
}

export default PartnershipList;
