import { Box } from "@mui/material";
import React from "react";
import styles from "./index.module.scss";

type Props = {
  icon: React.ReactNode;
};

export default function PersonalizedIconButton({ icon }: Props) {
  return <Box className={styles.iconButtonWrapper}>{icon}</Box>;
}
