import { Stack } from '@mui/material';
import React from 'react';
import styles from './index.module.scss';
import { FaCheck } from 'react-icons/fa6';
import { MdOutlineAddPhotoAlternate } from 'react-icons/md';
import { FaVideo } from 'react-icons/fa';
import { LuFileSpreadsheet } from 'react-icons/lu';

type Props = {
  text: string;
  handleSelectIcon: (icon: string) => void;
  selectedIcons: string[];
};

function textToIcon(text: string) {
  switch (text) {
    case 'Fotos':
      return <MdOutlineAddPhotoAlternate />;
    case 'Videos':
      return <FaVideo />;
    case 'Planilhas':
      return <LuFileSpreadsheet />;
    default:
      return <MdOutlineAddPhotoAlternate />;
  }
}

export default function IconSelector({
  text,
  handleSelectIcon,
  selectedIcons,
}: Props) {
  const isSelected = selectedIcons.includes(text);

  return (
    <Stack
      direction="row"
      className={`${styles.iconSelectorWrapper} ${
        isSelected ? styles.selected : ''
      }`}
      spacing={1}
      onClick={() => handleSelectIcon(text)}
    >
      {isSelected ? <FaCheck /> : <>{textToIcon(text)}</>}

      <p>{text}</p>
    </Stack>
  );
}
