import React from 'react';

import { apiRequest } from './apiUtils';
import { SuccessType } from '../domain';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';

type Props = {
  email: string;
  password: string;
  userType: string;
  username: string;
  onCreate: (login: SuccessType) => void;
};

export default async function CreateUserStandard({
  email,
  password,
  userType,
  username,
  onCreate,
}: Props) {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password,
    );
    const user = userCredential.user;

    const response = await apiRequest({
      endpoint: 'api/users/',
      method: 'POST',
      data: {
        email: email,
        username: username,
        user_type: userType,
      },
    });

    onCreate({
      message: 'Conta criada com sucesso!',
      isSuccess: true,
    });
  } catch (error) {
    const errorCode = (error as any).code;
    const errorMessage = (error as any).message;
    console.log(errorCode, errorMessage);

    onCreate({
      message: 'Erro ao criar conta: ' + errorMessage,
      isSuccess: false,
    });
  }
}
