import { Stack } from '@mui/material';
import React from 'react';
import Categories from './extra/Categories';
import PartnershipList from './extra/PartnershipList';
import styles from './index.module.scss';

function SearchPartnership() {
  return (
    <Stack spacing={2}>
      <p className={styles.titleSearch}>Parcerias</p>
      <Categories />
      <PartnershipList />
    </Stack>
  );
}

export default SearchPartnership;
