import  { createContext, useContext } from 'react';

interface FiltersContextType {
  timeInterval : Date[];
  setTimeInterval : (value : Date[]) => void,
  status: string,
  setStatus : (value : string) => void,
  city: string;
  setCity : (value : string) => void,
}


const FiltersContext = createContext<FiltersContextType>({
    timeInterval: [new Date(Date.now() - 7 * 24 * 60 * 60 * 1000), new Date()],
    setTimeInterval: () => {},
  status : 'Todos os status',
  setStatus : () => {},
  city: 'Todas as cidades',
  setCity : () => {},
});

const useFiltersContext = () => useContext(FiltersContext);

export { FiltersContext, useFiltersContext };