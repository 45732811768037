import { Box, Stack } from '@mui/material';
import React from 'react';
import styles from '../index.module.scss';
import PersonalizedModal from '../../../components/personalizedModal';
import FirstCounterPart from './FirstCounterPart';
import SecondCounterPart from './SecondCounterPart';
import ThirdCounterPart from './ThirdCounterPart';
import { NewCounterPart, NewTask, PartnershipCreate } from '../dtos';
import dayjs from 'dayjs';
import CounterpartButton from '../extra/CounterpartButton';
import PersonalizedTextArea from '../../../components/personalizedTextArea';
import { apiRequest } from '../../../api/apiUtils';

type Props = {
  setPartnershipCreateInfo: (value: PartnershipCreate) => void;
  partnershipCreateInfo: PartnershipCreate;
};

const defaultNewTask = {
  partnership_id: 0,
  title: '',
  responsible_email: '',
  due_date: dayjs(new Date()),
  description: '',
  type: [],
};

const defaultNewCounterPart = {
  title: '',
  description: '',
};

function Contrapartidas({
  setPartnershipCreateInfo,
  partnershipCreateInfo,
}: Props) {
  const [newTask, setNewTask] = React.useState<NewTask>(defaultNewTask);
  const [newCounterPart, setNewCounterPart] = React.useState<NewCounterPart>(
    defaultNewCounterPart,
  );
  const [type, setType] = React.useState<string>('');

  const steps = {
    'Criação da Contra-partida': (
      <FirstCounterPart
        newCounterPart={newCounterPart}
        setNewCounterPart={setNewCounterPart}
        newTask={newTask}
        setNewTask={setNewTask}
        type={type}
      />
    ),
    'Detalhes contrapartida': (
      <SecondCounterPart
        newCounterPart={newCounterPart}
        setNewCounterPart={setNewCounterPart}
        newTask={newTask}
        setNewTask={setNewTask}
        type={type}
      />
    ),
    'Resumo e confirmação': (
      <ThirdCounterPart
        newCounterPart={newCounterPart}
        newTask={newTask}
        type={type}
      />
    ),
  };

  const [openCreate, setOpenCreate] = React.useState<boolean>(false);
  const [savedNewInfo, setSavedNewInfo] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (savedNewInfo) {
      if (type === 'CounterPart') {
        partnershipCreateInfo.counterparts.push(newCounterPart);
        setSavedNewInfo(false);
        setType('');
      } else {
        partnershipCreateInfo.tasks.push(newTask);
        setSavedNewInfo(false);
        setType('');
      }
    }
    console.log(partnershipCreateInfo);
  }, [savedNewInfo, partnershipCreateInfo]);

  React.useEffect(() => {
    if (openCreate) {
      setNewTask(defaultNewTask);
      setNewCounterPart(defaultNewCounterPart);
    }
  }, [openCreate]);

  const handleTextChange = (value: string) => {
    setPartnershipCreateInfo({
      ...partnershipCreateInfo,
      relevant_info_for_brand: value,
    });
    console.log(partnershipCreateInfo);
  };

  return (
    <Stack
      width="70%"
      spacing={2}
      marginTop="48px"
      marginBottom={'32px'}
      className={styles.counterPartWrapper}
    >
      {openCreate && (
        <PersonalizedModal
          steps={steps}
          openModal={openCreate}
          setOpenModal={setOpenCreate}
          setSavedNewInfo={setSavedNewInfo}
        ></PersonalizedModal>
      )}
      <p className={styles.title}>Contrapartidas</p>
      <p className={styles.sectionTitle}>Contrapartidas da marca</p>
      {partnershipCreateInfo.counterparts.map((item, index) => (
        <Box key={index} onClick={() => setType('CounterPart')}>
          <CounterpartButton
            title={item.title}
            add={false}
            setOpenCreate={setOpenCreate}
            setPartnershipCreateInfo={setPartnershipCreateInfo}
            partnershipCreateInfo={partnershipCreateInfo}
          />
        </Box>
      ))}
      <Box onClick={() => setType('CounterPart')}>
        <CounterpartButton
          add={true}
          setOpenCreate={setOpenCreate}
          setPartnershipCreateInfo={setPartnershipCreateInfo}
          partnershipCreateInfo={partnershipCreateInfo}
        />
      </Box>
      <p className={styles.sectionTitle}>Contrapartidas do produtor</p>
      {partnershipCreateInfo.tasks.map((item, index) => (
        <Box key={index} onClick={() => setType('Task')}>
          <CounterpartButton
            title={item.title}
            add={false}
            date={item.due_date.format('DD/MM/YYYY')}
            setOpenCreate={setOpenCreate}
            setPartnershipCreateInfo={setPartnershipCreateInfo}
            partnershipCreateInfo={partnershipCreateInfo}
          />
        </Box>
      ))}
      <Box onClick={() => setType('Task')}>
        <CounterpartButton
          add={true}
          setOpenCreate={setOpenCreate}
          setPartnershipCreateInfo={setPartnershipCreateInfo}
          partnershipCreateInfo={partnershipCreateInfo}
        />
      </Box>
      <p className={styles.sectionTitle}>Dados relevantes para marca</p>
      <PersonalizedTextArea
        placeholder="Alinhar demais dados relevantes para a Marca e nossas
        orientações (treinamentos, idade, gênero, etc.)"
        setStoredText={handleTextChange}
      />
    </Stack>
  );
}

export default Contrapartidas;
