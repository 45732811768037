import { MenuProps } from "antd";

export const items: MenuProps["items"] = [
  {
    key: "Análise pendente",
    label: <a>Análise pendente</a>,
  },
  {
    key: "Análise concluída",
    label: <a>Análise concluída</a>,
  },
  {
    key: "Análise indisponível",
    label: <a>Análise indisponível</a>,
  },
  {
    key: "Análise cancelada",
    label: "Análise cancelada",
  },
];
