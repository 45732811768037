import React from "react";
import styles from "./index.module.scss";
import { Box, Stack } from "@mui/material";

type Props = {
  data: { [key: string]: string };
};

export default function DataTable({ data }: Props) {
  return (
    <Stack className={styles.tableWrapper}>
      {Object.entries(data).map(([key, value]) => (
        <Box key={key} className={styles.tableRow}>
          <Box className={styles.tableCell}>{value}</Box>
          <Box className={styles.tableCellLow}>{key}</Box>
        </Box>
      ))}
    </Stack>
  );
}
