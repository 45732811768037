import { Stack, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import styles from "./index.module.scss";
import SearchableDataTable from "../../components/searchDataTable";
import ReportDetails from "./ReportDetails";
import reports from "../../resources/reportsData.json";
import { ReportsData } from "./dtos";

export default function Reports() {
  const [detailsId, setDetailsId] = React.useState<string | null>(null);
  const [reportsData, setReportsData] = React.useState<ReportsData | null>(
    reports
  );
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up("md"));

  React.useEffect(() => {
    if (!isMobile && reportsData) {
      setDetailsId(reportsData?.Eventos[0].id);
    }
  }, [isMobile, reportsData]);

  return (
    <Stack className={styles.reportWrapper}>
      <p style={{ textAlign: !isMobile ? "center" : undefined }}>Relatórios</p>
      {!detailsId ? (
        <SearchableDataTable setDetailsId={setDetailsId} data={reportsData} />
      ) : (
        <ReportDetails
          reportsData={reportsData}
          setReportsData={setReportsData}
          setDetailsId={setDetailsId}
        />
      )}
    </Stack>
  );
}
