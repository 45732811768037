import React, { createContext, useContext, useState } from 'react';
import { User as FirebaseUser } from 'firebase/auth';

interface MenuContextType {
  selectedArea: string;
  setSelectedArea: (area: string) => void;
  user: FirebaseUser | null;
  setUser: (user: FirebaseUser | null) => void;
}

const MenuContext = createContext<MenuContextType>({
  selectedArea: 'Home',
  setSelectedArea: () => {},
  user: null,
  setUser: () => {},
});

const useMenuContext = () => useContext(MenuContext);

export { MenuContext, useMenuContext };
