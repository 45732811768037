import { Route, Routes } from 'react-router-dom';
import Login from './areas/Login';
import { Box } from '@mui/material';
import styles from './App.module.scss';
import Home from './areas/Home';
import ProtectedRoute from './utils/ProtectedRoutes';
import AuthObserver from './utils/authObserver';
import Reports from './areas/Reports';
import BetaReportArea from './areas/BetaReportArea';
import { ThemeProvider } from '@emotion/react';
import theme from './utils/themes';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Box className={styles.appStyles}>
        <AuthObserver>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/reports/:id" element={<BetaReportArea />} />
            <Route
              path="/home"
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />
          </Routes>
        </AuthObserver>
      </Box>
    </ThemeProvider>
  );
}

export default App;
