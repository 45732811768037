import { Box, Stack } from '@mui/material';
import React from 'react';
import { iconStatus } from './PartnershipPreview';
import styles from './extra.module.scss';
import { Status } from '../dtos';
import { useSearchPartnershipContext } from '../../../hooks/SearchPartnershipContext';

function StatusBar() {
  const status: Status[] = [
    'Todos',
    'Enviado',
    'Requer aceite',
    'Assinado',
    'Criar nova parceria',
  ];
  const [selectedStatus, setSelectedStatus] = React.useState<Status>('Todos');

  const { setIsCreatingNewPartnership } = useSearchPartnershipContext();

  return (
    <Stack spacing={6} direction="row" className={styles.statusBarWrapper}>
      {status.map((item: Status, index) => {
        return (
          <Stack
            direction="row"
            spacing={0.5}
            className={`${styles.statusItem} ${
              item === selectedStatus && styles.selected
            }`}
            alignItems={'center'}
            onClick={() => {
              if (item !== 'Criar nova parceria') {
                setSelectedStatus(item);
              } else {
                setIsCreatingNewPartnership(true);
              }
            }}
          >
            <Box
              className={
                item === selectedStatus
                  ? styles.statusIconSelected
                  : styles.statusIcon
              }
            >
              {iconStatus(item, 16)}
            </Box>
            <p
              className={
                item === selectedStatus
                  ? styles.statusTextSelected
                  : styles.statusText
              }
            >
              {item}
            </p>
          </Stack>
        );
      })}
    </Stack>
  );
}

export default StatusBar;
