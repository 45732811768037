import { Box, Grid, Stack } from "@mui/material";
import React from "react";
import styles from "./mobile.module.scss";
import { FaBoxesStacked } from "react-icons/fa6";
import { IoCloseOutline } from "react-icons/io5";
import { useReportsContext } from "../../../../hooks/ReportsContext";
import { FaCheck } from "react-icons/fa6";
import { MdInsertLink } from "react-icons/md";
import PersonalizedButton from "../../../../components/personalizedButton";
import { FaClock } from "react-icons/fa";
import { Tooltip } from "antd";

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export default function DeliveriesMobile({ isOpen, setIsOpen }: Props) {
  const { selectedPartnership, setSelectedPartnership } = useReportsContext();

  return (
    <Box
      className={styles.backgroundWrapper}
      onClick={() => {
        setIsOpen(false);
      }}
    >
      <Stack
        className={styles.deliveriesModalWrapper}
        onClick={(e) => e.stopPropagation()}
      >
        <Stack direction="row" className={styles.headerModal} spacing={1}>
          <Stack direction={"row"} spacing={2} alignItems={"center"}>
            <FaBoxesStacked className={styles.headerIcon} fontSize="28px" />
            <p className={styles.headerTitle}>Entregas do produtor</p>
          </Stack>
          <IoCloseOutline
            className={styles.headerIcon}
            onClick={() => setIsOpen(false)}
          />
        </Stack>
        <Box className={styles.tasksWrapper}>
          <Stack width={"100%"} rowGap={"24px"}>
            {selectedPartnership &&
              selectedPartnership.Entregas.map((item, index) => {
                return (
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    alignItems={"center"}
                    className={styles.task}
                    key={index}
                  >
                    <Grid item xs={9.5}>
                      <p>{item.Nome_entrega}</p>
                    </Grid>

                    <Grid item xs={2.5}>
                      {item.Link ? (
                        <Box
                          className={styles.linkWrapper}
                          onClick={() => window.open(item.Link, "_blank")}
                        >
                          <MdInsertLink className={styles.linkIcon} />
                        </Box>
                      ) : (
                        <Tooltip title="Entrega pendente">
                          <Box className={styles.linkWrapperPending}>
                            <FaClock className={styles.linkIcon} />
                          </Box>
                        </Tooltip>
                      )}
                    </Grid>
                  </Grid>
                );
              })}
          </Stack>
        </Box>
        <Box className={styles.footer}>
          <Box sx={{ width: "200px" }}>
            <PersonalizedButton
              text="Fechar"
              onClick={() => {
                setIsOpen(false);
              }}
              variant="primary"
            />
          </Box>
        </Box>
      </Stack>
    </Box>
  );
}
