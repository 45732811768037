import React from "react";
import PersonalizedDateRangePicker from "../personalizedDateRangePicker";
import { MenuProps, Modal } from "antd";
import { Box, Stack } from "@mui/material";
import FilterButton from "./FilterButton";
import CityAutoComplete from "./CityAutoComplete";
import { FaLocationPin } from "react-icons/fa6";
import PersonalizedDropDown from "../personalizedDropDown";
import { FaChevronDown } from "react-icons/fa";
import { FaCalendarAlt } from "react-icons/fa";
import { useFiltersContext } from "../../hooks/FiltersContext";
import styles from "./index.module.scss";
import { items } from "./dtos";
import PersonalizedButton from "../personalizedButton";

export default function FilterSelector() {
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const { timeInterval, setTimeInterval, city, setCity, status, setStatus } =
    useFiltersContext();

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        title="Selecione o intervalo de tempo que deseja ver"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={700}
      >
        <Box className={styles.modalWrapper}>
          <PersonalizedDateRangePicker
            timeInterval={timeInterval}
            setTimeInterval={setTimeInterval}
          />
        </Box>
      </Modal>
      <Stack
        margin="20px 20px 0px 20px"
        direction="row"
        justifyContent={"space-between"}
      >
        <FilterButton
          icon={<FaCalendarAlt />}
          label="Intervalo"
          onClick={() => {
            setIsModalOpen(true);
          }}
        >
          <Box minWidth={"200px"}>
            <p>{`${timeInterval[0].toLocaleDateString()} - ${timeInterval[1].toLocaleDateString()}`}</p>
          </Box>
        </FilterButton>

        <FilterButton icon={<FaChevronDown />} label="Status">
          <PersonalizedDropDown
            items={items}
            value={status}
            setValue={setStatus}
          />
        </FilterButton>

        <FilterButton icon={<FaLocationPin />} label="Cidade">
          <CityAutoComplete city={city} setCity={setCity} />
        </FilterButton>
      </Stack>
    </>
  );
}
