import React, { useState } from "react";
import { Table } from "antd";
import type { TableColumnsType } from "antd";
import { motion, AnimatePresence } from "framer-motion";
import styles from "./index.module.scss";
import { FaLink } from "react-icons/fa6";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { ReportsData } from "../../../areas/Reports/dtos";

interface PartnershipType {
  key: React.Key;
  marca: string;
  entregasCompletas: number;
  status: string;
}

interface DataType {
  key: React.Key;
  evento: string;
  cidade: string;
  dataDoEvento: string;
  status: string;
  redirect: React.ReactNode;
  parcerias?: PartnershipType[];
}

const columns: TableColumnsType<DataType> = [
  {
    title: "Evento",
    dataIndex: "evento",
    key: "evento",
    sorter: (a, b) => a.evento.localeCompare(b.evento),
    sortDirections: ["ascend", "descend"],
  },
  {
    title: "Cidade",
    dataIndex: "cidade",
    key: "cidade",
    sorter: (a, b) => a.cidade.localeCompare(b.cidade),
    sortDirections: ["ascend", "descend"],
  },
  {
    title: "Data do evento",
    dataIndex: "dataDoEvento",
    key: "dataDoEvento",
    sorter: (a, b) => a.dataDoEvento.localeCompare(b.dataDoEvento),
    sortDirections: ["ascend", "descend"],
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    sorter: (a, b) => a.status.localeCompare(b.status),
    sortDirections: ["ascend", "descend"],
  },
  {
    title: "Redirecionar",
    dataIndex: "redirect",
    key: "redirect",
    width: "10%",
  },
];

const partnershipColumns: TableColumnsType<PartnershipType> = [
  {
    title: "Marca",
    dataIndex: "marca",
    key: "marca",
  },
  {
    title: "Entregas Completas",
    dataIndex: "entregasCompletas",
    key: "entregasCompletas",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
];

type Props = {
  setDetailsId: React.Dispatch<React.SetStateAction<string | null>>;
  dataImported: ReportsData;
};

export default function DataTable({ setDetailsId, dataImported }: Props) {
  const { id } = useParams();
  const [expandedRows, setExpandedRows] = useState<React.Key[]>([]);

  const handleExpand = (expanded: boolean, record: DataType) => {
    if (expanded) {
      setExpandedRows([...expandedRows, record.key]);
    } else {
      setExpandedRows(expandedRows.filter((key) => key !== record.key));
    }
  };

  const filteredEvent = dataImported.Eventos.find((event) => event.id === id);

  const data: DataType[] = filteredEvent
    ? [
        {
          key: filteredEvent.id,
          evento: filteredEvent.Nome,
          cidade: filteredEvent.Cidade,
          dataDoEvento: filteredEvent.Data,
          status: filteredEvent.Status,
          redirect: (
            <Box
              className={styles.redirectButton}
              onClick={() => {
                setDetailsId(filteredEvent.id);
              }}
            >
              <FaLink fontSize={"20px"} />
            </Box>
          ),
        },
      ]
    : [];

  return (
    <Table
      columns={columns}
      dataSource={data}
      rowClassName={styles.row}
      rowHoverable={false}
      className={styles.tableWrapper}
      expandable={{
        expandedRowRender: (record) => (
          <AnimatePresence>
            {expandedRows.includes(record.key) && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
              >
                <Table
                  columns={partnershipColumns}
                  dataSource={record.parcerias}
                  pagination={false}
                  rowKey={(record) => record.key as string}
                />
              </motion.div>
            )}
          </AnimatePresence>
        ),
        rowExpandable: (record) => !!record.parcerias,
        onExpand: handleExpand,
        expandedRowKeys: expandedRows,
      }}
      rowKey={(record) => record.key as string}
    />
  );
}
