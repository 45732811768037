import React, { createContext, useContext, useState } from 'react';
import { User as FirebaseUser } from "firebase/auth";
import { InformacoesMacLife, ReportsData } from '../areas/Reports/dtos';

interface ReportsContextType {
  reportsData : ReportsData | null;
  setReportsData: (value: ReportsData | null) => void;
  selectedPartnership : InformacoesMacLife | null;
  setSelectedPartnership: (value: InformacoesMacLife | null) => void;
}


const ReportsContext = createContext<ReportsContextType>({
  reportsData: null,
  setReportsData: () => {},
  selectedPartnership: null,
  setSelectedPartnership: () => {}
});

const useReportsContext = () => useContext(ReportsContext);

export { ReportsContext, useReportsContext };