import React from "react";
import styles from "./ImageDots.module.scss";
import { Box } from "@mui/material";

type Props = {
  selected: boolean;
};

function Dot({ selected }: Props) {
  return <Box className={selected ? styles.selectedDot : styles.dot}></Box>;
}

export default Dot;
