import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import styles from "./index.module.scss";

type Text = {
  title: string;
  description: string;
  fontSize: number;
  fontWeight: number;
};

type Props = {
  icon?: React.ReactNode;
  color: string;
  size: string;
  texts: Text[];
  attachment?: React.ReactNode;
};

export default function PersonalizedCard({
  icon,
  color,
  size,
  texts,
  attachment,
}: Props) {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("md"));

  const getBoxSize = (size: string) => {
    switch (size) {
      case "small":
        return { width: 350, height: 152, maxWidth: 287 };
      case "medium":
        return { width: 360, height: 244, maxWidth: 277 };
      case "large":
        return { width: 636, height: 430, maxWidth: 277 };
      default:
        return { width: 0, height: 0 };
    }
  };

  const boxSize = getBoxSize(size);

  return (
    <Box
      className={styles.cardWrapper}
      sx={{
        width: boxSize.width,
        height: !isMediumScreen ? "fit-content" : boxSize.height,
        maxWidth: !isMediumScreen ? boxSize.maxWidth : undefined,
      }}
    >
      {icon && (
        <Box className={styles.icon} sx={{ backgroundColor: color }}>
          {icon}
        </Box>
      )}

      {texts.map((text, index) => (
        <Stack
          spacing={0.2}
          key={index}
          justifyContent={"center"}
          sx={{
            position: "relative",
            maxWidth: !isMediumScreen ? "240px" : undefined,
          }}
        >
          <p className={styles.title}>{text.title}</p>
          {!attachment ? (
            <p
              style={{
                fontSize: text.fontSize,
                fontWeight: text.fontWeight,
              }}
              className={styles.description}
            >
              {text.description}
            </p>
          ) : (
            <Box className={styles.positionChart}>{attachment}</Box>
          )}
        </Stack>
      ))}
    </Box>
  );
}
