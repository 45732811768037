import { Stack } from '@mui/material';
import React from 'react';
import PersonalizedInput from '../../../components/personalizedInput';
import PersonalizedTextArea from '../../../components/personalizedTextArea';
import PersonalizedSelectorIcon from '../../../components/personalizedSelectorIcon';
import { NewCounterPart, NewTask } from '../dtos';

type Props = {
  newCounterPart: NewCounterPart;
  setNewCounterPart: (counterPart: NewCounterPart) => void;
  newTask: NewTask;
  setNewTask: (task: NewTask) => void;
  type: string;
};

function SecondCounterPart({
  newCounterPart,
  setNewCounterPart,
  newTask,
  setNewTask,
  type,
}: Props) {
  const handleInputChange = (
    field: keyof NewTask | keyof NewCounterPart,
    value: any,
  ) => {
    if (type === 'CounterPart') {
      setNewCounterPart({
        ...newCounterPart,
        [field]: value,
      });
    } else {
      setNewTask({
        ...newTask,
        [field]: value,
      });
    }
  };

  React.useEffect(() => {
    console.log(newCounterPart);
  }, [newCounterPart]);

  const options = ['Fotos', 'Videos', 'Planilhas'];

  return (
    <Stack spacing={3}>
      <p style={{ fontWeight: 600 }}>
        Agora vamos inserir algumas informações que não são obrigatórios mas
        podem ajudar no entendimento e na realização das contrapartidas.
      </p>
      <PersonalizedTextArea
        setStoredText={(value) => handleInputChange('description', value)}
        placeholder="Coloque informações que ajudem a entender o que deve ser entregue nessa contrapartida, instruções de realização, etc... "
      />
      {type === 'Task' && (
        <Stack spacing={1}>
          <p style={{ fontWeight: 600 }}>
            Selecione os tipos de entrega que idealmente seriam feitas, mais de
            uma pode ser selecionada
          </p>
          <PersonalizedSelectorIcon
            options={options}
            selectedIcons={newTask.type}
            setSelectedIcons={(selectedIcons) =>
              handleInputChange('type', selectedIcons)
            }
          />
        </Stack>
      )}
    </Stack>
  );
}

export default SecondCounterPart;
