import React from "react";
import styles from "./index.module.scss";
import MultiSelectMenu from "../../components/multiSelectMenu";
import EditMenu from "./Menus/editMenu";
import InfoMenu from "./Menus/infoMenu";
import { Box } from "@mui/material";

export default function ProfileInfo() {
  return (
    <Box className={styles.profileWrapper} sx={{ padding: "32px" }}>
      <MultiSelectMenu
        sections={{ Perfil: <InfoMenu />, Editar: <EditMenu /> }}
      />
    </Box>
  );
}
