import React from 'react';
import { NewCounterPart, NewTask } from '../dtos';
import dayjs from 'dayjs';
import IconSelector from '../../../components/personalizedSelectorIcon/iconSelector';
import { Stack } from '@mui/material';

type Props = {
  newCounterPart: NewCounterPart;
  newTask: NewTask;
  type: string;
};

function keyToTitle(key: keyof NewTask | keyof NewCounterPart, type: string) {
  switch (key) {
    case 'description':
      return 'Descrição da Contrapartida:';
    case 'responsible_email':
      return 'Email do responsável';
    case 'due_date':
      return 'Data limite para contrapartida';
    case 'type':
      return 'Tipo de entregas sugeridas';
    case 'title':
      return 'Titulo da entrega:';
    default:
      return '';
  }
}

function formatValue(key: keyof NewTask | keyof NewCounterPart, value: any) {
  if (key === 'due_date') {
    return dayjs(value).format('DD/MM/YYYY');
  }
  if (key === 'type') {
    return (
      <Stack direction="row" flexWrap={'wrap'} spacing={2}>
        {value.map((deliveryType: string, index: number) => (
          <IconSelector
            key={index}
            text={deliveryType}
            handleSelectIcon={() => {}}
            selectedIcons={[]}
          />
        ))}
      </Stack>
    );
  }
  if (Array.isArray(value)) {
    return value.join(', ');
  }
  return value;
}

function ThirdCounterPart({ newCounterPart, newTask, type }: Props) {
  const data = type === 'CounterPart' ? newCounterPart : newTask;
  const keys = Object.keys(data) as (keyof typeof data)[];

  return (
    <Stack spacing={2}>
      {keys.map((key) => (
        <Stack spacing={1} key={key}>
          <p style={{ fontWeight: '600' }}>{keyToTitle(key, type)}</p>
          <p>{formatValue(key, data[key])}</p>
        </Stack>
      ))}
    </Stack>
  );
}

export default ThirdCounterPart;
