import { Box, Stack } from '@mui/material';
import React from 'react';
import PersonalizedInput from '../../../components/personalizedInput';
import styles from './extra.module.scss';
import { relative } from 'path';

function CreateCategorie() {
  const colors = [
    '#000000', // Preto
    '#6836e8', // Roxo
    '#7939ef', // Púrpura
    '#3639c9', // Azul claro
    '#165ef0', // Azul
    '#2f8ffb', // Azul escuro
    '#0a89b0', // Verde
    '#05934f', // Cor personalizada (branco)
  ];

  const [selectedColor, setSelectedColor] = React.useState<string>('#7939ef');

  return (
    <div>
      <Stack direction="column" spacing={2}>
        <PersonalizedInput
          inputType="text"
          text="Qual o nome da categoria"
          variant="primary"
        />
        <Stack direction="column" spacing={1}>
          <p>Aparência</p>
          <Stack direction="row" alignItems={'center'} spacing={2}>
            {colors.map((color) => (
              <Box
                sx={{
                  width: '32px',
                  height: '32px',
                  backgroundColor: color,
                  borderRadius: '50%',
                  position: 'relative',
                  '&:before': {
                    borderTop: `3px solid ${color} !important`,
                    borderColor: `${color} !important`,
                  },
                }}
                className={
                  color === selectedColor ? styles.selectedColor : undefined
                }
                onClick={() => {
                  setSelectedColor(color);
                }}
              />
            ))}
            <Stack
              direction="row"
              spacing={1}
              alignItems={'center'}
              height={'32px'}
            >
              <p>Cor customizada:</p>
              <Box width="100px">
                <PersonalizedInput
                  inputType="text"
                  variant="primary"
                  text="HEX"
                />
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
}

export default CreateCategorie;
