import React from 'react';
import styles from './LoginForm.module.scss';
import { Box, Checkbox, Stack, Typography } from '@mui/material';
import AlternateEmailRoundedIcon from '@mui/icons-material/AlternateEmailRounded';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import PersonalizedInput from '../../../components/personalizedInput';
import Logo from '../../../resources/LogoSponsor.png';
import PersonalizedButton from '../../../components/personalizedButton';
import PersonalizedCheckBox from '../../../components/personalizedCheckBox';
import {
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
  browserLocalPersistence,
  browserSessionPersistence,
} from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../../firebase';
import SignInGoogle from '../../../api/signInGoogle';
import { SuccessType } from '../../../domain';

type Props = {
  setSuccessfulLogin: (value: SuccessType) => void;
  setIsRegistering: (value: boolean) => void;
};

function LoginForm({ setSuccessfulLogin, setIsRegistering }: Props) {
  const navigate = useNavigate();
  const [keepLogged, setKeepLogged] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');

  const onLogin = () => {
    const persistence = keepLogged
      ? browserLocalPersistence
      : browserSessionPersistence;
    setPersistence(auth, persistence)
      .then(() => {
        return signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            const user = userCredential.user;
            const jwt = user.getIdToken();
            setSuccessfulLogin({
              message: 'Login bem sucedido!',
              isSuccess: true,
            });
            setTimeout(() => {
              navigate('/home');
            }, 3000);
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
          });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
      });
  };

  const handleGoogleSignIn = async () => {
    await SignInGoogle(() => {
      navigate('/home');
    });
  };

  return (
    <Stack
      direction={'column'}
      spacing={2}
      alignItems={'center'}
      justifyContent={'center'}
      height={'100%'}
      sx={{ backgroundColor: 'white', zIndex: '2' }}
    >
      <Stack
        direction={'column'}
        spacing={4}
        alignItems={'center'}
        justifyContent={'center'}
        height={'100%'}
        width={'525px'}
      >
        <img src={Logo} width="100px" height="100px"></img>
        <p className={styles.title}>Bem vindo!</p>
        <p className={styles.body}>
          Faça login ou crie sua conta na dashboard e tenha acesso a nossa
          ferramenta!
        </p>
        <PersonalizedInput
          inputType="Email"
          text="Email"
          variant="primary"
          icon={<AlternateEmailRoundedIcon />}
          setStoredText={setEmail}
        />
        <Stack width="100%">
          <PersonalizedInput
            inputType="password"
            variant="primary"
            text="Senha"
            icon={<LockRoundedIcon />}
            setStoredText={setPassword}
          />
          <Stack
            direction={'row'}
            width={'100%'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Stack alignItems={'center'} direction="row">
              <PersonalizedCheckBox
                id="keep-logged"
                text="Manter logado"
                checkboxValue={keepLogged}
                setCheckBoxValue={setKeepLogged}
              ></PersonalizedCheckBox>
            </Stack>
            <p className={styles.forgotPassword}>Recuperar senha!</p>
          </Stack>
          <Stack direction="column" spacing={1} width={'100%'}>
            <PersonalizedButton
              text="Entrar"
              variant="primary"
              onClick={onLogin}
            />
            <Stack
              direction="row"
              alignItems={'center'}
              justifyContent={'center'}
              spacing={1}
            >
              <Box className={styles.line}></Box>
              <p className={styles.lineText}>ou</p>
              <Box className={styles.line}></Box>
            </Stack>
            <PersonalizedButton
              text="Registrar"
              variant="secondary"
              onClick={() => {
                setIsRegistering(true);
              }}
            />
            <div>
              <button onClick={handleGoogleSignIn}>Continuar com Google</button>
            </div>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default LoginForm;
