import React from 'react';
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
} from 'recharts';

type Props = {
  infoToBeShown: string;
  data: { name: string; value: number }[];
  formatter?: string;
};

export default function Chart({ infoToBeShown, data, formatter = '' }: Props) {
  const CustomBar = (props: any) => {
    const { fill, x, y, width, height, value, name } = props;
    const color = name.includes(infoToBeShown) ? '#33ce60' : '#000041';
    return (
      <g>
        <Rectangle x={x} y={y} width={width} height={height} fill={color} />
        <text
          x={x + width + 5}
          y={y + height / 2}
          dy={4}
          fill="#000"
          fontSize={10}
          textAnchor="start"
        >
          {formatter === 'money' ? `R$${value}` : value}
        </text>
        <text
          x={x - 10}
          y={y + height / 2}
          dy={4}
          fill="#000"
          fontSize={10}
          textAnchor="end"
        ></text>
      </g>
    );
  };

  const formatLabel = (value: any) => {
    return formatter === 'money' ? `R$${value}` : value;
  };

  return (
    <BarChart
      width={600}
      height={350}
      data={data}
      layout="vertical"
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
      style={{ fontSize: '10px' }}
    >
      <XAxis type="number" />
      <YAxis type="category" dataKey="name" />
      <Tooltip formatter={formatLabel} />
      <Bar dataKey="value" fill="#33ce60" shape={<CustomBar />}>
        <LabelList dataKey="value" position="right" formatter={formatLabel} />
      </Bar>
    </BarChart>
  );
}
