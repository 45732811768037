import React, { createContext, useContext, useState } from 'react';
import { User as FirebaseUser } from "firebase/auth";
import { InformacoesMacLife, ReportsData } from '../areas/Reports/dtos';

interface SearchPartnershipContextType {
    isCreatingNewPartnership : boolean;
    setIsCreatingNewPartnership: (value: boolean) => void;
}


const SearchPartnershipContext = createContext<SearchPartnershipContextType>({
    isCreatingNewPartnership : false,
    setIsCreatingNewPartnership: () => {}

});

const useSearchPartnershipContext = () => useContext(SearchPartnershipContext);

export { SearchPartnershipContext, useSearchPartnershipContext };