import { Grid } from "@mui/material";
import React from "react";
import ProfileCard from "./profileCard";
import styles from "./index.module.scss";
import ProfileInfo from "./profileInfo";

export default function Profile() {
  return (
    <Grid container className={styles.sessionWrapper}>
      <Grid item xs={12}>
        Perfil
      </Grid>
      <Grid item xs={4}>
        <ProfileCard />
      </Grid>
      <Grid item xs={8}>
        <ProfileInfo />
      </Grid>
    </Grid>
  );
}
