import { Box, Stack } from "@mui/material";
import React from "react";
import PersonalizedIconButton from "../personalizedIconButton";
import { IoMdSearch } from "react-icons/io";
import { FaChevronDown } from "react-icons/fa";
import styles from "./index.module.scss";
import PersonalizedButton from "../personalizedButton";
import SearchBar from "../searchBar";
import DataTable from "./DataTable/index";
import { ReportsData } from "../../areas/Reports/dtos";

type Props = {
  setDetailsId: React.Dispatch<React.SetStateAction<string | null>>;
  data: ReportsData | null;
};

export default function SearchableDataTable({ setDetailsId, data }: Props) {
  return (
    <Stack direction="column" spacing={4}>
      <SearchBar />
      {data && <DataTable setDetailsId={setDetailsId} dataImported={data} />}
    </Stack>
  );
}
