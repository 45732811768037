import { Box, Stack } from '@mui/material';
import React from 'react';
import { BsSendArrowUpFill } from 'react-icons/bs';
import { MdEdit } from 'react-icons/md';
import { FaCheck } from 'react-icons/fa';
import { FaFile } from 'react-icons/fa';
import styles from './extra.module.scss';
import { PiDotsNineBold } from 'react-icons/pi';
import { IoMdAddCircle } from 'react-icons/io';
import { categoriePreview } from './Categorie';
import { motion } from 'framer-motion';

export function iconStatus(status: string, fontSize: number) {
  switch (status) {
    case 'Enviado':
      return <BsSendArrowUpFill fontSize={fontSize} />;
    case 'Requer aceite':
      return <MdEdit fontSize={fontSize} />;
    case 'Assinado':
      return <FaCheck fontSize={fontSize} />;
    case 'Criar nova parceria':
      return <IoMdAddCircle fontSize={fontSize} />;
    default:
      return <PiDotsNineBold fontSize={fontSize} />;
  }
}

type Props = {
  status: string;
  title: string;
  index: number;
};

function PartnershipPreview({ status, title, index }: Props) {
  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      className={styles.partnershipPreviewWrapper}
      component={motion.div}
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: 0.1 * index }}
    >
      <Stack direction="row" justifyContent={'space-between'}>
        <Stack
          direction="row"
          className={`${styles.statusWrapper}`}
          alignItems={'center'}
          spacing={1}
        >
          {iconStatus(status, 24)}
          <p>{status}</p>
        </Stack>
        <FaFile className={styles.fileWrapper} />
      </Stack>
      <Stack direction="column" spacing={1}>
        <p className={styles.partnershipPreviewSubTitle}>25/07/2024</p>
        <p className={styles.partnershipPreviewTitle}>{title}</p>
      </Stack>
      <Stack direction="row" spacing={2} alignItems={'center'}>
        {categoriePreview('Bebidas', 18)}
        <Box
          sx={{
            width: '4px',
            height: '4px',
            borderRadius: '50%',
            backgroundColor: '#d9d9d9',
          }}
        ></Box>
        <p className={styles.download}>Download</p>
      </Stack>
    </Stack>
  );
}

export default PartnershipPreview;
