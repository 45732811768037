import React from 'react';
import PersonalizedInput from '../../../components/personalizedInput';
import { Box, Stack } from '@mui/material';
import styles from './extra.module.scss';
import ResponsiveDatePickers from '../../../components/personalizedButtonDatePicker';
import dayjs from 'dayjs';
import { FaCheck } from 'react-icons/fa6';
import { PartnershipCreate } from '../dtos';

type Props = {
  part: string;
  setPartnershipCreateInfo: (value: PartnershipCreate) => void;
  partnershipCreateInfo: PartnershipCreate;
};

function Aceptment({
  part,
  setPartnershipCreateInfo,
  partnershipCreateInfo,
}: Props) {
  const [aceptment, setAceptment] = React.useState<boolean>(false);

  const handleTextChange = (value: string, key: string) => {
    if (key === 'Marca') {
      setPartnershipCreateInfo({
        ...partnershipCreateInfo,
        brand_signed_document: value,
      });
    } else {
      setPartnershipCreateInfo({
        ...partnershipCreateInfo,
        producer_signed_document: value,
      });
    }
  };

  React.useEffect(() => {
    if (part === 'a Marca') {
      setPartnershipCreateInfo({
        ...partnershipCreateInfo,
        signed_by_brand: aceptment,
      });
    } else {
      setPartnershipCreateInfo({
        ...partnershipCreateInfo,
        signed_by_producer: aceptment,
      });
    }
  }, [aceptment]);

  React.useEffect(() => {
    console.log(partnershipCreateInfo);
  }, [partnershipCreateInfo]);

  return (
    <Stack spacing={2} className={styles.aceptmentWrapper}>
      <Box
        onClick={() => {
          setAceptment(!aceptment);
        }}
        className={styles.aceptmentButton}
      >
        {aceptment && <FaCheck className={styles.aceptmentIcon} />}
      </Box>
      <p>
        Ao assinar este termo, {part} concorda em cumprir todas as
        contrapartidas e obrigações detalhadas no cadastro inicial e em
        colaborar com o Produtor para o sucesso do evento.
      </p>
      <Stack width={'60%'} spacing={2}>
        <PersonalizedInput
          variant="transparent-black"
          text="Nome do responsável"
          inputType="text"
        />
        <PersonalizedInput
          variant="transparent-black"
          text="Documento do responsável"
          inputType="text"
          setStoredText={(text: string) => handleTextChange(text, part)}
        />
      </Stack>
    </Stack>
  );
}

export default Aceptment;
