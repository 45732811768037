import { Stack } from '@mui/material';
import React from 'react';
import styles from '../index.module.scss';
import Aceptment from '../extra/Aceptment';
import { PartnershipCreate } from '../dtos';

type Props = {
  setPartnershipCreateInfo: (value: PartnershipCreate) => void;
  partnershipCreateInfo: PartnershipCreate;
};

function Aceite({ setPartnershipCreateInfo, partnershipCreateInfo }: Props) {
  return (
    <Stack width="70%" spacing={2} marginTop="48px" marginBottom={'32px'}>
      <p className={styles.title}>Aceite</p>
      <Aceptment
        part={'a Marca'}
        setPartnershipCreateInfo={setPartnershipCreateInfo}
        partnershipCreateInfo={partnershipCreateInfo}
      />
      <Aceptment
        part={'o Produtor'}
        setPartnershipCreateInfo={setPartnershipCreateInfo}
        partnershipCreateInfo={partnershipCreateInfo}
      />
    </Stack>
  );
}

export default Aceite;
