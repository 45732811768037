import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

export const firebaseConfig = {
  apiKey: "AIzaSyBuiuk-eq0QwWt8QXuzEBhu8V5r1gQ8-8g",
  authDomain: "sponsorize-auth.firebaseapp.com",
  projectId: "sponsorize-auth",
  storageBucket: "sponsorize-auth.appspot.com",
  messagingSenderId: "114238197394",
  appId: "1:114238197394:web:883883f2976186df8ba04b",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export default app;
