import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import styles from "./index.module.scss";
import logo from "../../resources/LogoBGBranco.png";

export default function MenuHeader() {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));
  return (
    <Stack
      direction={"row"}
      spacing={1}
      sx={{ paddingTop: "24px" }}
      alignItems={"center"}
    >
      <img src={logo} width="30px" height={"30px"}></img>
      {isLargeScreen && <Box className={styles.headerTitle}>Sponsorize</Box>}
    </Stack>
  );
}
