import React from 'react';
import MultistepProgress from '../../components/multistepProgress';
import { MdOutlineCreateNewFolder } from 'react-icons/md';
import { FaRegHandshake } from 'react-icons/fa';
import { TiTickOutline } from 'react-icons/ti';
import { Box, Stack } from '@mui/material';
import styles from './index.module.scss';
import PersonalizedButton from '../../components/personalizedButton';
import Criação from './FormParts/Criação';
import Contrapartidas from './FormParts/Contrapartidas';
import Aceite from './FormParts/Aceite';
import { useSearchPartnershipContext } from '../../hooks/SearchPartnershipContext';
import { PartnershipCreate } from './dtos';
import dayjs from 'dayjs';
import { apiRequest } from '../../api/apiUtils';

async function createPartnership(newPartnership: PartnershipCreate) {
  await apiRequest({
    endpoint: 'api/create_partnership/',
    method: 'POST',
    data: newPartnership,
  });
}

const GetFormPart = (
  stage: string,
  partnershipCreateInfo: PartnershipCreate,
  setPartnershipCreateInfo: (value: PartnershipCreate) => void,
) => {
  switch (stage) {
    case 'Criação':
      return (
        <Criação
          partnershipCreateInfo={partnershipCreateInfo}
          setPartnershipCreateInfo={setPartnershipCreateInfo}
        />
      );
    case 'Contrapartidas':
      return (
        <Contrapartidas
          partnershipCreateInfo={partnershipCreateInfo}
          setPartnershipCreateInfo={setPartnershipCreateInfo}
        />
      );
    case 'Aceite':
      return (
        <Aceite
          partnershipCreateInfo={partnershipCreateInfo}
          setPartnershipCreateInfo={setPartnershipCreateInfo}
        />
      );
    default:
      return (
        <Criação
          partnershipCreateInfo={partnershipCreateInfo}
          setPartnershipCreateInfo={setPartnershipCreateInfo}
        />
      );
  }
};

export default function CreateNewPartnership() {
  const stages = ['Criação', 'Contrapartidas', 'Aceite'];
  const [selectedStage, setSelectedStage] = React.useState<number>(0);

  const { setIsCreatingNewPartnership } = useSearchPartnershipContext();

  const [partnershipCreateInfo, setPartnershipCreateInfo] =
    React.useState<PartnershipCreate>({
      brand_id: 1,
      category: 'Geral',
      counterparts: [],
      tasks: [],
      event_name: 'Mac',
      relevant_info_for_brand: '',
      brand_signed_document: '',
      producer_signed_document: '',
      signed_by_brand: false,
      signed_by_producer: false,
    });

  return (
    <>
      <Box className={styles.formWrapper}>
        <MultistepProgress
          stages={[
            { title: 'Criação', icon: <MdOutlineCreateNewFolder /> },
            { title: 'Contrapartidas', icon: <FaRegHandshake /> },
            { title: 'Aceite', icon: <TiTickOutline /> },
          ]}
          selectedStage={stages[selectedStage]}
        />
      </Box>

      {GetFormPart(
        stages[selectedStage],
        partnershipCreateInfo,
        setPartnershipCreateInfo,
      )}

      <Stack alignItems="center" justifyContent={'center'} width={'100%'}>
        <Stack spacing={2} direction="row" width={'30%'}>
          <PersonalizedButton
            text="Voltar"
            variant="secondary"
            onClick={() => {
              if (selectedStage === 0) {
                setIsCreatingNewPartnership(false);
              } else {
                setSelectedStage(selectedStage - 1);
              }
            }}
          />
          <PersonalizedButton
            text={selectedStage < stages.length - 1 ? 'Avançar' : 'Enviar'}
            variant="primary"
            onClick={() => {
              if (selectedStage >= stages.length - 1) {
                createPartnership(partnershipCreateInfo);
              }
              setSelectedStage(selectedStage + 1);
            }}
          />
        </Stack>
      </Stack>
    </>
  );
}
