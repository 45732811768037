import React from "react";
import Box from "@mui/material/Box";
import styles from "./index.module.scss";

import loginBackground from "../../resources/loginBackground.jpg";

type Props = {
  children: React.ReactNode;
};

export default function LoginLayout({ children }: Props) {
  return (
    <Box
      className={styles.layoutWrapper}
      sx={{ background: `url(${loginBackground})` }}
    >
      {children}
    </Box>
  );
}
