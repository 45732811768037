import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import stylesDesktop from './index.module.scss';
import stylesMobile from './mobile.module.scss';
import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import { IoClose } from 'react-icons/io5';
import PersonalizedButton from '../personalizedButton';
import SuccessScreen from '../../areas/Login/form/SuccessScreen/SuccessScreen';

type Props = {
  steps: { [title: string]: React.ReactNode };
  openModal: boolean;
  setOpenModal: (openModal: boolean) => void;
  setSavedNewInfo?: (savedNewInfo: boolean) => void;
};

export default function PersonalizedModal({
  steps,
  openModal,
  setOpenModal,
  setSavedNewInfo,
}: Props) {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  const [modalNumber, setModalNumber] = useState<number>(0);
  const stepsSize = Object.keys(steps).length;
  const modalRef = useRef<HTMLDivElement>(null);
  const [maxHeight, setMaxHeight] = useState<number>(0);
  const [appearSuccessScreen, setAppearSuccessScreen] =
    React.useState<boolean>(false);

  useLayoutEffect(() => {
    if (modalRef.current) {
      setMaxHeight(modalRef.current.scrollHeight);
    }
  }, [modalNumber, openModal]);

  const handleConclude = () => {
    setAppearSuccessScreen(true);
    setTimeout(() => {
      setAppearSuccessScreen(false);
      setOpenModal(false);
    }, 2000);
  };

  return (
    <>
      {openModal && (
        <Box
          className={
            isMediumScreen
              ? stylesDesktop.backgroundWrapper
              : stylesMobile.backgroundWrapper
          }
        >
          <Box
            ref={modalRef}
            className={
              isMediumScreen
                ? stylesDesktop.modalWrapper
                : stylesMobile.modalWrapper
            }
            style={{ maxHeight: maxHeight || '500px' }}
          >
            {Object.entries(steps).map(
              ([title, node], index) =>
                index === modalNumber && (
                  <React.Fragment key={title}>
                    <Box className={stylesDesktop.modalHeader}>
                      <Stack
                        direction="row"
                        alignItems={'center'}
                        justifyContent={'space-between'}
                      >
                        <Stack direction="column">
                          <p className={stylesDesktop.stepIndicator}>
                            Passo {modalNumber + 1}/{stepsSize}
                          </p>
                          <p className={stylesDesktop.headerTitle}>{title}</p>
                        </Stack>
                        <IoClose
                          fontSize={'32px'}
                          onClick={() => {
                            setOpenModal(false);
                          }}
                        />
                      </Stack>
                    </Box>
                    <Box className={stylesDesktop.modalBody}>{node}</Box>
                    <Stack
                      className={stylesDesktop.modalFooter}
                      direction="row"
                      justifyContent={'flex-end'}
                    >
                      <Stack direction="row" width={'300px'} spacing={2}>
                        <PersonalizedButton
                          text="Voltar"
                          onClick={() => {
                            setModalNumber(modalNumber - 1);
                          }}
                          variant="blue-second"
                          disabled={modalNumber === 0}
                        />
                        <PersonalizedButton
                          text={
                            modalNumber === stepsSize - 1
                              ? 'Concluir'
                              : 'Continuar'
                          }
                          onClick={() => {
                            if (modalNumber === stepsSize - 1) {
                              handleConclude();
                              if (setSavedNewInfo) {
                                setSavedNewInfo(true);
                              }
                            } else {
                              setModalNumber(modalNumber + 1);
                            }
                          }}
                          variant="blue-first"
                        />
                      </Stack>
                    </Stack>
                    {appearSuccessScreen && (
                      <SuccessScreen message="Criação bem sucedida" />
                    )}
                  </React.Fragment>
                ),
            )}
          </Box>
        </Box>
      )}
    </>
  );
}
