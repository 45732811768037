import axios from 'axios';
import { getAuth } from 'firebase/auth';

// Configuração do Axios
const backendApi = axios.create({
  baseURL: 'http://localhost:8000', // Base URL do backend
});

// Interceptor para adicionar o token de autorização
backendApi.interceptors.request.use(
  async (config) => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      const idToken = await user.getIdToken();
      config.headers.Authorization = `Bearer ${idToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default backendApi;
