import { signInWithGooglePopup } from '../firebase.utils';
import { apiRequest } from './apiUtils';

const SignInGoogle = async (onSignIn: () => void) => {
  try {
    const result = await signInWithGooglePopup();
    const idToken = await result.user.getIdToken();

    const response = await apiRequest({
      endpoint: 'api/users/google',
      method: 'POST',
      data: {
        token: idToken,
      },
    });

    console.log('User logged in and saved:', response);
    onSignIn();
  } catch (error) {
    console.error('Error logging in with Google:', error);
  }
};

export default SignInGoogle;
