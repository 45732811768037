import React from 'react';

import { Box, Grid, Stack } from '@mui/material';
import AlternateEmailRoundedIcon from '@mui/icons-material/AlternateEmailRounded';
import LockRoundedIcon from '@mui/icons-material/LockRounded';

import styles from './RegisterScreen.module.scss';
import PersonalizedInput from '../../../../components/personalizedInput';
import PersonalizedButton from '../../../../components/personalizedButton';
import PersonalizedCheckBox from '../../../../components/personalizedCheckBox';
import PersonalizedRadioGroup from '../../../../components/personalizedRadioGroup';
import { SuccessType, UserType } from '../../../../domain';
import CreateUserStandard from '../../../../api/createUser';

type Props = {
  setSuccessfulLogin: (value: SuccessType) => void;
  setIsRegistering: (value: boolean) => void;
};

function RegisterScreen({ setSuccessfulLogin, setIsRegistering }: Props) {
  const [agreedUserTerms, setAgreedUserTerms] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [username, setUsermame] = React.useState<string>('');
  const [type, setType] = React.useState<string>('');

  const handleCreateUser = async () => {
    await CreateUserStandard({
      email: email,
      password: password,
      username: username,
      userType: type,
      onCreate: (success: SuccessType) => {
        setSuccessfulLogin(success);
      },
    });
  };

  return (
    <Box className={styles.registerWrapper}>
      <Stack
        direction="column"
        spacing={4}
        width="80%"
        maxWidth={'1332px'}
        minWidth={'1332px'}
      >
        <p className={styles.title}>Crie sua conta!</p>
        <PersonalizedInput
          variant="transparent"
          inputType="email"
          text="Nome de usuário"
          setStoredText={setUsermame}
        ></PersonalizedInput>
        <PersonalizedInput
          variant="transparent"
          inputType="email"
          text="Email para registro"
          icon={<AlternateEmailRoundedIcon />}
          setStoredText={setEmail}
        ></PersonalizedInput>
        <PersonalizedInput
          variant="transparent"
          inputType="password"
          text="Digite a senha"
          icon={<LockRoundedIcon />}
          setStoredText={setPassword}
        ></PersonalizedInput>
        <PersonalizedInput
          variant="transparent"
          inputType="password"
          text="Digite a senha novamente"
          icon={<LockRoundedIcon />}
        ></PersonalizedInput>
        <PersonalizedRadioGroup
          label={'Como você se enquadra?'}
          name={'teste'}
          options={[
            { label: 'Marca', value: UserType.Brand },
            { label: 'Produtor', value: UserType.Producer },
          ]}
          onChange={(type) => setType(type)}
        />
        <PersonalizedCheckBox
          id="user-terms-agree"
          text="Concordo com os termos de uso"
          setCheckBoxValue={setAgreedUserTerms}
          checkboxValue={agreedUserTerms}
        />
        <Grid container columnGap={4}>
          <Grid item xs={5.85}>
            <PersonalizedButton
              text="Já tenho uma conta"
              variant="third"
              onClick={() => {
                setIsRegistering(false);
              }}
            />
          </Grid>
          <Grid item xs={5.85}>
            <PersonalizedButton
              text="Registrar"
              variant="primary"
              onClick={handleCreateUser}
            />
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
}

export default RegisterScreen;
