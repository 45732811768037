import { Box, Stack } from "@mui/material";
import React from "react";
import PersonalizedIconButton from "../personalizedIconButton";
import { IoMdSearch } from "react-icons/io";
import { FaChevronDown } from "react-icons/fa";
import styles from "./index.module.scss";
import PersonalizedButton from "../personalizedButton";
import Filters from "./Filters";

export default function SearchBar() {
  const [appearFilters, setAppearFilters] = React.useState<boolean>(false);

  return (
    <Box width={"100%"} sx={{ position: "relative" }}>
      {appearFilters && <Filters />}

      <Stack
        direction={"row"}
        width={"100%"}
        justifyContent={"space-between"}
        alignItems={"center"}
        className={styles.searchBarWrapper}
      >
        <input
          className={styles.inputSearch}
          placeholder="Pesquise por relatórios aqui..."
        ></input>
        <Stack
          direction="row"
          height={"71px"}
          alignItems={"center"}
          spacing={1}
          marginRight={"8px"}
        >
          <Box minWidth={"140px"}>
            <PersonalizedButton
              text="Filtros"
              variant="secondary"
              onClick={() => {
                setAppearFilters(!appearFilters);
              }}
              icon={<FaChevronDown />}
            />
          </Box>
          <PersonalizedIconButton icon={<IoMdSearch />} />
        </Stack>
      </Stack>
    </Box>
  );
}
