import { Stack } from '@mui/material';
import React from 'react';
import Categorie from './Categorie';
import styles from './extra.module.scss';
import { IoMdAddCircle } from 'react-icons/io';
import { FaAngleDown } from 'react-icons/fa';
import PersonalizedModal from '../../../components/personalizedModal';
import CreateCategorie from './CreateCategorie';

function Categories() {
  const [openCreate, setOpenCreate] = React.useState<boolean>(false);
  const [selectedCategorie, setSelectedCategorie] = React.useState<string>('');
  const [shownCategorie, setShownCategorie] = React.useState<string[]>([
    'Energéticos',
    'Alcoólicos',
    'Sucos',
    'fasfsafafs',
  ]);

  const [showAll, setShowAll] = React.useState<boolean>(false);

  return (
    <Stack direction="column" className={styles.categoriesWrapper} spacing={2}>
      <PersonalizedModal
        steps={{
          'Criar nova categoria': <CreateCategorie />,
        }}
        openModal={openCreate}
        setOpenModal={setOpenCreate}
      />
      <Stack direction="row" justifyContent={'space-between'}>
        <p className={styles.categoriesTitle}>Categorias</p>
        <Stack
          direction="row"
          spacing={1}
          alignItems={'center'}
          onClick={() => {
            setOpenCreate(true);
          }}
          sx={{ cursor: 'pointer' }}
        >
          <IoMdAddCircle className={styles.addIcon} />
          <p className={styles.addText}>Adicionar categoria</p>
        </Stack>
      </Stack>
      <Stack
        direction={'row'}
        rowGap={2}
        columnGap={2}
        flexWrap={'wrap'}
        justifyContent={'center'}
      >
        {shownCategorie.map((categorie, index) => {
          return (
            <Categorie
              text={categorie}
              index={index}
              selectedCategorie={selectedCategorie}
              setSelectedCategorie={setSelectedCategorie}
            />
          );
        })}
      </Stack>
      <Stack
        direction={'row'}
        spacing={1}
        alignItems={'center'}
        justifyContent={'center'}
        className={styles.expand}
        onClick={() => {
          if (!showAll) {
            setShownCategorie([
              'Energéticos',
              'Alcoólicos',
              'Sucos',
              'fsafasfa',
              'fasfassfasf',
              'fasfafasfafffff',
              'kjhlhljhlhj',
              'czxcxzvz',
            ]);
            setShowAll(true);
          } else {
            setShownCategorie([
              'Energéticos',
              'Alcoólicos',
              'Sucos',
              'sfafsaffas',
            ]);
            setShowAll(false);
          }
        }}
      >
        <p>Mais categorias</p>
        <FaAngleDown />
      </Stack>
    </Stack>
  );
}

export default Categories;
