import { Box, Stack } from "@mui/material";
import React from "react";
import styles from "./index.module.scss";

type Props = {
  sections: { [key: string]: React.ReactNode };
};

export default function MultiSelectMenu({ sections }: Props) {
  const [selectedMenu, setSelectedMenu] = React.useState<string>(
    Object.keys(sections)[0]
  );

  return (
    <Stack width={"100%"} direction={"column"} className={styles.menuWrapper}>
      <Stack width={"100%"} spacing={2} direction="row">
        {Object.entries(sections).map(([key]) => (
          <Box
            onClick={() => {
              setSelectedMenu(key);
            }}
            className={`${styles.headerItem} ${
              key === selectedMenu ? styles.selected : styles.unselected
            }`}
          >
            {key}
          </Box>
        ))}
      </Stack>

      <Stack width={"100%"} direction="column">
        {sections[selectedMenu]}
      </Stack>
    </Stack>
  );
}
