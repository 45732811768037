import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase";

const AuthObserver = ({ children }: { children: JSX.Element }) => {
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      const isReportsRoute = location.pathname.startsWith("/reports/");
      if (user) {
        const timer = setTimeout(() => {
          navigate("/home");
        }, 3000);
        return () => {
          clearTimeout(timer);
        };
      } else if (!isReportsRoute) {
        navigate("/");
      }
    });

    return () => unsubscribe();
  }, [navigate, location]);

  return <>{children}</>;
};

export default AuthObserver;
