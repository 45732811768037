import { Box, Stack } from '@mui/material';
import React from 'react';
import { IoMdMore } from 'react-icons/io';
import styles from './extra.module.scss';
import { motion } from 'framer-motion';

export function categoriePreview(categorie: string, fontSize: number) {
  return (
    <Stack direction={'row'} spacing={1} alignItems={'center'}>
      <Box className={styles.categorieBox}></Box>
      <p className={styles.categorieText} style={{ fontSize: `${fontSize}px` }}>
        #{categorie}
      </p>
    </Stack>
  );
}

type Props = {
  text: string;
  index: number;
  selectedCategorie: string;
  setSelectedCategorie: (categorie: string) => void;
};

function Categorie({
  text,
  index,
  selectedCategorie,
  setSelectedCategorie,
}: Props) {
  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      className={`${styles.categorieWrapper} ${
        selectedCategorie === text ? styles.selectedCategorie : undefined
      }`}
      component={motion.div}
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: 0.1 * index }}
      onClick={() => {
        setSelectedCategorie(text);
      }}
    >
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <p className={styles.secondaryText}>Criada 29/03/2024</p>
        <IoMdMore fontSize={28} color="b1b1b1" />
      </Stack>
      <Stack direction="column">
        {categoriePreview(text, 28)}
        <p className={styles.secondaryText}>39 parcerias</p>
      </Stack>
    </Stack>
  );
}

export default Categorie;
