import backendApi from './backendApi';

type Method = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

interface ApiRequestParams {
  endpoint: string;
  method?: Method;
  data?: any;
}

// Função genérica para chamadas ao backend
export const apiRequest = async ({ endpoint, method = 'GET', data = null }: ApiRequestParams) => {
  try {
    const response = await backendApi({
      url: endpoint,
      method: method,
      data: data,
    });
    return response.data;
  } catch (error) {
    console.error('API request error:', error);
    throw error;
  }
};
